import { useCallback, useEffect, useState } from 'react';
import FormInput from './formInput';
import LoadingSpinner from './loadingSpinner';
import {
  validateClientId,
  validateEmail,
  validateImei,
  validateLabel,
  validateMqttHost,
  validatePassword,
  validatePort,
  validatePrefix,
  validateUsername,
  validateWebSocketPath
} from '../utils/validation';
import Checkbox from './ui/checkbox';
import { ICloudToCloudBody } from '../App';

// appears if customer has entered an IMEI of an apollo device

interface IRegisterMqttPasswordForm {
  t: any;
  loading: boolean;
  handlePasswordRegistration: (
    password: String,
    email: String
  ) => Promise<void>;
  imeiValidated: boolean;
  customerIdValidated: boolean;
}

export const RegisterMqttPasswordForm: React.FC<IRegisterMqttPasswordForm> = ({
  t,
  loading,
  handlePasswordRegistration,
  imeiValidated,
  customerIdValidated
}) => {
  const [formValidated, setFormValidated] = useState<boolean>(true);
  const [formFirstRender, setFormFirstRender] = useState<boolean>(true);

  const [email, setEmail] = useState(process.env.REACT_APP_DEV_EMAIL || '');
  const [password, setPassword] = useState(
    process.env.REACT_APP_DEV_PASSWORD || ''
  );
  const [confirmPassword, setConfirmPassword] = useState(
    process.env.REACT_APP_DEV_PASSWORD || ''
  );
  const [passwordValidated, setPasswordValidated] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState<boolean>(false);
  const [emailValidated, setEmailValidated] = useState<boolean>(true);
  const [confPasswordEmpty, setConfPasswordEmpty] = useState(false);

  const validateConfirmPassword = useCallback(
    (
      confirmPwd: string,
      pwd: string
    ): { validated: boolean; message: string } => {
      if (!confirmPwd) {
        return {
          validated: false,
          message: t('mqtt.errors.password_confirmation_required')
        };
      }

      if (confirmPwd !== pwd) {
        return {
          validated: false,
          message: t('mqtt.errors.passwords_do_not_match')
        };
      }

      return { validated: true, message: '' };
    },
    [t]
  );
  const handleFormValidation = useCallback(() => {
    setFormFirstRender(false);
    let formValid: boolean = true;

    let passwordValid: boolean = true;
    let confPasswordValid: boolean = true;
    let emailValid: boolean = true;

    // check if values of the starter form lose validation
    if (!customerIdValidated) formValid = false;
    if (!imeiValidated) formValid = false;

    if (email && password && confirmPassword) {
      passwordValid = validatePassword(password).validated;
      setPasswordValidated(passwordValid);

      confPasswordValid = validateConfirmPassword(
        confirmPassword,
        password
      ).validated;
      setPasswordsMatch(confPasswordValid);

      emailValid = validateEmail(email).validated;
      setEmailValidated(emailValid);
    } else {
      setFormValidated(false);
      if (!password) setPasswordEmpty(true);
      if (!confirmPassword) setConfPasswordEmpty(true);
      if (!email) setEmailEmpty(true);
      return false;
    }

    if (!passwordValid || !confPasswordValid || !emailValid) formValid = false;

    if (formValid === false) {
      setFormValidated(false);
      return false;
    } else {
      setFormValidated(true);
      return true;
    }
  }, [
    customerIdValidated,
    imeiValidated,
    password,
    confirmPassword,
    email,
    validateConfirmPassword
  ]);

  // recheck validation if entered values change
  useEffect(() => {
    if (!formFirstRender) handleFormValidation();
  }, [
    imeiValidated,
    customerIdValidated,
    formFirstRender,
    handleFormValidation,
    password,
    confirmPassword,
    email
  ]);

  const handleFormSubmission = async (e: React.FormEvent) => {
    e.preventDefault();
    const formValid = handleFormValidation();
    if (formValid) {
      handlePasswordRegistration(password, email);
    }
  };

  return (
    <form className="p-6" onSubmit={handleFormSubmission} noValidate>
      <div className="w-full grid grid-cols-12 gap-4 p-6">
        <hr className="col-span-12 my-12 w-[80%] relative m-auto left-0 right-0" />
      </div>
      <div className="my-8 text-left">
        <label htmlFor="form-password">{t('mqtt.form_labels.email')}</label>
        <div className="grid grid-cols-12 join join-horizontal">
          <FormInput
            setEmpty={setEmailEmpty}
            isEmpty={emailEmpty}
            input={email}
            isValidated={emailValidated}
            setValidated={setEmailValidated}
            validationFct={validateEmail}
            name="email"
            type="email"
            className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
            autoFocus
            wrapperClassName="col-span-12"
            placeholder={t('mqtt.form_labels.email_placeholder')}
            autoComplete="off"
            required
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setEmail(e.currentTarget.value)
            }
          />
        </div>
      </div>

      <label htmlFor="form-password">{t('mqtt.form_labels.password')}</label>
      <div className="grid grid-cols-12 join join-horizontal">
        <FormInput
          setEmpty={setPasswordEmpty}
          isEmpty={passwordEmpty}
          validationFct={validatePassword}
          isValidated={passwordValidated}
          setValidated={setPasswordValidated}
          input={password}
          type="password"
          className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                join-item
            "
          wrapperClassName="col-span-12"
          placeholder={t('mqtt.form_labels.password_placeholder')}
          autoComplete="new-password"
          required
          maxLength={36}
          onChange={(e: any) => {
            setPassword(e.currentTarget.value);
          }}
        />
      </div>

      <div className="my-8 text-left">
        <label htmlFor="form-confirm-password">
          {t('mqtt.form_labels.password_confirm')}
        </label>
        <div className="grid grid-cols-12 join join-horizontal">
          <FormInput
            setEmpty={setConfPasswordEmpty}
            isEmpty={confPasswordEmpty}
            validationFct={(confPwd: string) => {
              return validateConfirmPassword(confPwd, password);
            }}
            isValidated={passwordsMatch}
            setValidated={setPasswordsMatch}
            input={confirmPassword}
            type="password"
            className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                join-item
            "
            wrapperClassName="col-span-12"
            placeholder="Passwort wiederholen"
            autoComplete="new-password"
            required
            maxLength={36}
            onChange={(e: any) => {
              setConfirmPassword(e.currentTarget.value);
            }}
          />
        </div>
      </div>

      <button
        type="submit"
        className="btn p-4 btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase as-hide-if-loading disabled:rounded-none disabled:text-white"
        disabled={!formValidated || loading}
      >
        {loading && <LoadingSpinner />}
        {t('mqtt.form_labels.set_password')}
      </button>
    </form>
  );
};

interface IMqttLoginForm {
  t: any;
  loading: boolean;
  handleMqttLogin: (password: String) => Promise<void>;
  handlePasswordReset: (email: String) => Promise<void>;
  imeiValidated: boolean;
  customerIdValidated: boolean;
}

export const MqttLoginForm: React.FC<IMqttLoginForm> = ({
  t,
  loading,
  handleMqttLogin,
  handlePasswordReset,
  imeiValidated,
  customerIdValidated
}) => {
  const [formValidated, setFormValidated] = useState<boolean>(true);
  const [formFirstRender, setFormFirstRender] = useState<boolean>(true);

  const [password, setPassword] = useState(
    process.env.REACT_APP_DEV_PASSWORD || ''
  );
  const [passwordValidated, setPasswordValidated] = useState<boolean>(true);
  const [passwordEmpty, setPasswordEmpty] = useState(false);

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [emailEmpty, setEmailEmpty] = useState<boolean>(false);
  const [emailValidated, setEmailValidated] = useState<boolean>(true);

  const handleFormValidation = useCallback(() => {
    setFormFirstRender(false);
    let formValid: boolean = true;

    let passwordValid: boolean = true;
    let emailValid: boolean = true;

    // check if values of the starter form lose validation
    if (!customerIdValidated) formValid = false;
    if (!imeiValidated) formValid = false;

    if (!showForgotPassword) {
      passwordValid = !!password;
      setPasswordValidated(passwordValid);
      if (!password) setPasswordEmpty(true);
      if (!password || !passwordValid) {
        setFormValidated(false);
        formValid = false;
      }
    } else {
      emailValid = validateEmail(email).validated;
      setEmailValidated(emailValid);
      if (!email) setEmailEmpty(true);
      if (!email || !emailValid) {
        setFormValidated(false);
        formValid = false;
      }
    }

    if (formValid === false) {
      setFormValidated(false);
      return false;
    } else {
      setFormValidated(true);
      return true;
    }
  }, [password, email, showForgotPassword, customerIdValidated, imeiValidated]);

  // recheck validation if entered values change
  useEffect(() => {
    if (!formFirstRender) handleFormValidation();
  }, [
    imeiValidated,
    customerIdValidated,
    formFirstRender,
    handleFormValidation,
    password,
    email,
    showForgotPassword,
    passwordEmpty,
    emailEmpty
  ]);

  useEffect(() => {
    setFormValidated(true);
    setEmailEmpty(false);
    setPasswordEmpty(false);
    setEmail(process.env.REACT_APP_DEV_EMAIL || '');
    setPassword(process.env.REACT_APP_DEV_PASSWORD || '');
    setPasswordValidated(true);
    setEmailValidated(true);
  }, [showForgotPassword]);

  const handleFormSubmission = async (e: React.FormEvent) => {
    e.preventDefault();
    const formValid = handleFormValidation();
    if (formValid) {
      if (showForgotPassword) {
        handlePasswordReset(email);
      } else {
        if (!password) {
          setPasswordEmpty(true);
          return;
        }
        handleMqttLogin(password);
      }
    }
  };

  return (
    <form className="p-6" onSubmit={handleFormSubmission} noValidate>
      <div className="w-full grid grid-cols-12 gap-4 p-6">
        <hr className="col-span-12 my-12 w-[80%] relative m-auto left-0 right-0" />
      </div>

      {/* Passwortfeld */}
      {!showForgotPassword && (
        <div className="my-8 text-left">
          <label htmlFor="form-password">
            {t('mqtt.form_labels.password')}
          </label>
          <div className="grid grid-cols-12 join join-horizontal">
            <FormInput
              setEmpty={setPasswordEmpty}
              isEmpty={passwordEmpty}
              validationFct={() => ({ validated: !!password, message: '' })}
              isValidated={passwordValidated}
              setValidated={setPasswordValidated}
              input={password}
              type="password"
              className="
                uk-input
                as-input
                uk-form-large
                uk-background-muted
                join-item
            "
              wrapperClassName="col-span-12"
              placeholder={t('mqtt.form_labels.password_placeholder')}
              autoComplete="current-password"
              maxLength={36}
              autoFocus={true}
              required
              onChange={(e: any) => setPassword(e.currentTarget.value)}
            />
          </div>
          <div className="cursor-pointer">
            <span
              className="text-primary"
              onClick={() => {
                setShowForgotPassword(true);
              }}
            >
              {t('mqtt.form_labels.forgot_password')}
            </span>
          </div>
        </div>
      )}

      {showForgotPassword && (
        <div className="my-8 text-left">
          <label htmlFor="form-password">{t('mqtt.form_labels.email')}</label>
          <div className="grid grid-cols-12 join join-horizontal">
            <FormInput
              setEmpty={setEmailEmpty}
              isEmpty={emailEmpty}
              input={email}
              isValidated={emailValidated}
              setValidated={setEmailValidated}
              validationFct={validateEmail}
              name="email"
              type="email"
              className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
              wrapperClassName="col-span-12"
              placeholder={t('mqtt.form_labels.email_placeholder')}
              autoComplete="off"
              required
              onChange={(e: React.FormEvent<HTMLInputElement>) =>
                setEmail(e.currentTarget.value)
              }
            />
          </div>
          <div className="cursor-pointer">
            <span
              className="text-primary"
              onClick={() => {
                setShowForgotPassword(false);
              }}
            >
              {t('mqtt.form_labels.password_placeholder')}
            </span>
          </div>
        </div>
      )}

      {/* Absenden-Button */}
      <button
        type="submit"
        className="btn p-4 btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase as-hide-if-loading disabled:rounded-none disabled:text-white"
        disabled={loading || !formValidated}
      >
        {loading && <LoadingSpinner />}
        {showForgotPassword
          ? t('mqtt.form_labels.reset_password')
          : t('mqtt.form_labels.login')}
      </button>
    </form>
  );
};

interface ICloudToCloudForm {
  loading: boolean;
  t: any;
  imei: string;
  customerId: string;
  currentLanguage: string;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  endpoint: string;
  setEndpoint: React.Dispatch<React.SetStateAction<string>>;
  port: string;
  setPort: React.Dispatch<React.SetStateAction<string>>;
  tls: boolean;
  setTls: React.Dispatch<React.SetStateAction<boolean>>;
  websocketPath: string;
  setWebsocketPath: React.Dispatch<React.SetStateAction<string>>;
  authentication: boolean;
  setAuthentication: React.Dispatch<React.SetStateAction<boolean>>;
  websocket: boolean;
  setWebsocket: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  additionalImeis: string[];
  setAdditionalImeis: React.Dispatch<React.SetStateAction<string[]>>;
  customerIdValidated: boolean;
  imeiValidated: boolean;
  clientId: string;
  setClientId: React.Dispatch<React.SetStateAction<string>>;
  prefix: string;
  setPrefix: React.Dispatch<React.SetStateAction<string>>;
  handleFormSubmit: (body: ICloudToCloudBody) => Promise<void>;
  setMqttIdToDelete: React.Dispatch<React.SetStateAction<string>>;
  setShowDeleteBrokerModal: React.Dispatch<React.SetStateAction<boolean>>;
  storedBrokers: any[];
  setStoredBrokers: React.Dispatch<React.SetStateAction<any[]>>;
  token: String;
  mqttIdToDelete: string;
  additionalImeisInfo: any[];
  setAdditionalImeisInfo: React.Dispatch<React.SetStateAction<any[]>>;
  brokerIsDefault: boolean;
  setBrokerIsDefault: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloudToCloudForm: React.FC<ICloudToCloudForm> = ({
  loading,
  customerId,
  t,
  name,
  setName,
  endpoint,
  setEndpoint,
  port,
  setPort,
  tls,
  setTls,
  websocketPath,
  setWebsocketPath,
  authentication,
  setAuthentication,
  username,
  setUsername,
  password,
  setPassword,
  additionalImeis,
  setAdditionalImeis,
  customerIdValidated,
  imeiValidated,
  imei,
  currentLanguage,
  handleFormSubmit,
  prefix,
  setPrefix,
  clientId,
  setClientId,
  websocket,
  setWebsocket,
  setMqttIdToDelete,
  setShowDeleteBrokerModal,
  storedBrokers,
  setStoredBrokers,
  token,
  additionalImeisInfo,
  setAdditionalImeisInfo,
  brokerIsDefault,
  setBrokerIsDefault
}) => {
  const [formValidated, setFormValidated] = useState<boolean>(true);
  const [formFirstRender, setFormFirstRender] = useState<boolean>(true);

  const [nameEmpty, setNameEmpty] = useState<boolean>(false);
  const [labelValidated, setLabelValidated] = useState<boolean>(true);
  const [hostEmpty, setHostEmpty] = useState<boolean>(false);
  const [hostValidated, setHostValidated] = useState<boolean>(true);
  const [portEmpty, setPortEmpty] = useState<boolean>(false);
  const [portValidated, setPortValidated] = useState<boolean>(true);
  const [usernameEmpty, setUsernameEmpty] = useState<boolean>(false);
  const [usernameValidated, setUsernameValidated] = useState<boolean>(true);
  const [passwordEmpty, setPasswordEmpty] = useState<boolean>(false);
  const [passwordValidated, setPasswordValidated] = useState<boolean>(true);
  const [additionalImei, setAdditionalImei] = useState<string>('');
  const [additionalImeiEmpty, setAdditionalImeiEmpty] =
    useState<boolean>(false);
  const [additionalImeiValidated, setAdditionalImeiValidated] =
    useState<boolean>(true);
  const [additionalImeisError, setAdditionalImeisError] = useState<string>('');
  const [websocketPathEmpty, setWebsocketPathEmpty] = useState<boolean>(false);
  const [websocketPathValidated, setWebsocketPathValidated] =
    useState<boolean>(true);
  const [prefixEmpty, setPrefixEmpty] = useState<boolean>(false);
  const [prefixValidated, setPrefixValidated] = useState<boolean>(true);
  const [clientIdEmpty, setClientIdEmpty] = useState<boolean>(false);
  const [clientIdValidated, setClientIdValidated] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(`/api/v1/mqtt/assignment/${customerId}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const data = await res.json();
        setStoredBrokers(data);
      } catch (error) {
        console.error('Error fetching MQTT assignments:', error);
      }
    };

    fetchData();
  }, [customerId, token, setStoredBrokers]);

  const handleFormValidation = useCallback(() => {
    setFormFirstRender(false);
    let formValid: boolean = true;

    let nameValid: boolean = true;
    let hostValid: boolean = true;
    let portValid: boolean = true;
    let prefixValid: boolean = true;
    let clientIdValid: boolean = true;
    let usernameValid: boolean = true;
    let passwordValid: boolean = true;
    let additionalImeisValid: boolean = true;
    let websocketPathValid: boolean = true;

    // check if values of the starter form lose validation
    if (!customerIdValidated) formValid = false;
    if (!imeiValidated) formValid = false;

    if (name && endpoint) {
      nameValid = validateLabel(name).validated;
      setLabelValidated(nameValid);

      hostValid = validateMqttHost(endpoint).validated;
      setLabelValidated(hostValid);

      prefixValid = validatePrefix(prefix).validated;
      setPrefixValidated(prefixValid);

      clientIdValid = validateClientId(clientId).validated;
      setClientIdValidated(clientIdValid);

      if (!portEmpty) {
        portValid = validatePort(port).validated;
        setPortValidated(portValid);
      }

      if (authentication) {
        usernameValid = validateUsername(username).validated;
        setUsernameValidated(usernameValid);

        passwordValid = validatePassword(password).validated;
        setPasswordValidated(passwordValid);
      }

      if (websocket) {
        websocketPathValid = validateWebSocketPath(websocketPath).validated;
        setWebsocketPathValidated(websocketPathValid);
      }

      if (
        additionalImeis.filter((ad) => !validateImei(ad).validated)?.length >= 1
      )
        additionalImeisValid = false;
    } else {
      setFormValidated(false);
      if (!name) setNameEmpty(true);
      if (!endpoint) setHostEmpty(true);
      return false;
    }

    if (
      !nameValid ||
      !hostValid ||
      !portValid ||
      !usernameValid ||
      !passwordValid ||
      !additionalImeisValid ||
      !websocketPathValid ||
      !prefixValid ||
      !clientIdValid
    )
      formValid = false;

    if (formValid === false) {
      setFormValidated(false);
      return false;
    } else {
      setFormValidated(true);
      return true;
    }
  }, [
    customerIdValidated,
    imeiValidated,
    port,
    endpoint,
    name,
    username,
    password,
    additionalImeis,
    authentication,
    websocket,
    websocketPath,
    prefix,
    clientId,
    portEmpty
  ]);

  // recheck validation if entered values change
  useEffect(() => {
    if (!formFirstRender) handleFormValidation();
  }, [
    customerId,
    imeiValidated,
    customerIdValidated,
    formFirstRender,
    handleFormValidation,
    port,
    endpoint,
    username,
    password,
    additionalImei,
    authentication,
    websocket,
    tls,
    name,
    clientId,
    websocketPath,
    prefix,
    additionalImeis,
    brokerIsDefault
  ]);

  const handleFormSubmission = async (e: any) => {
    e.preventDefault();
    const formValid = handleFormValidation();
    if (formValid === true) {
      let body: ICloudToCloudBody = {
        imei: imei,
        customerId: customerId,
        language: currentLanguage,
        additionalImeis,
        connectionData: {
          clientId,
          endpoint: endpoint,
          password: authentication ? password : null,
          path: websocket ? websocketPath : null,
          port: port === '' ? 1883 : Number(port),
          prefix,
          tls,
          username: authentication ? username : null,
          websocket,
          authentication
        },
        name: customerId + '-' + name,
        brokerIsDefault
      };
      handleFormSubmit(body);
    }
  };

  const addToAdditionalIMEIs = async (pAdditionalImei: string) => {
    if (additionalImeis.includes(pAdditionalImei)) {
      setAdditionalImeisError(t('mqtt.errors.imei_already_added'));
      setAdditionalImeiValidated(true);
    } else if (validateImei(pAdditionalImei).validated) {
      setAdditionalImeiValidated(true);
      try {
        const res = await fetch(
          `/api/v1/deviceType?imei=${pAdditionalImei}&customerId=${customerId}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
        const data = await res.json();
        if (data.articleNumber) {
          if (data.type === 'cloudtocloud') {
            setAdditionalImeis((prev) => [...prev, pAdditionalImei]);
            setAdditionalImeisInfo((prev) => [
              ...prev,
              {
                imei: pAdditionalImei,
                type: data.type,
                articleNumber: data.articleNumber,
                mqttId: data.mqttId
              }
            ]);
            setAdditionalImei('');
            setAdditionalImeisError('');
            setAdditionalImeiValidated(true);
          } else {
            setAdditionalImeisError(t('mqtt.errors.device_not_compatible'));
            setAdditionalImeiValidated(true);
          }
        } else setAdditionalImeisError(t('mqtt.errors.imei_not_linked'));
      } catch (error) {
        console.error('Error fetching device information:', error);
      }
    } else setAdditionalImeiValidated(false);
  };

  return (
    <form id="cloudtocloudForm" className="p-6">
      <div className="w-full grid grid-cols-12 gap-4 p-6">
        <hr className="col-span-12 my-12 w-[80%] relative m-auto left-0 right-0" />
      </div>

      <h3 className="col-span-12">
        <b>{t('mqtt.mqtt_form.broker')}</b>
      </h3>

      {storedBrokers?.length >= 1 && (
        <ul className="bg-secondary/20 p-2 my-4">
          <span>{t('mqtt.mqtt_form.your_brokers')}</span>
          <span className="ml-2 text-gray-500 italic">
            {t('mqtt.mqtt_form.click_to_select')}
          </span>
          {storedBrokers.map((a, i) => (
            <li
              key={i}
              className="group list-disc ml-4 my-1 flex items-center hover:font-bold hover:text-primary cursor-pointer"
              onClick={() => {
                setName(a.name.replace(new RegExp(`^${customerId}-`), ''));
                setEndpoint(a.endpoint);
                setPort(a.port);
                setClientId(a.clientId);
                setPrefix(a.prefix);
                setTls(a.tls);
                setWebsocket(a.websocket);
                setAuthentication(a.authentication);
                setUsername(a.username);
                setBrokerIsDefault(a.brokerIsDefault);
              }}
            >
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowDeleteBrokerModal(true);
                  setMqttIdToDelete(a.id);
                }}
                type="button"
                className="text-lg mt-1 uk-text-danger hover:text-font-bold"
                aria-label={`Delete broker ${a}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="20px"
                  height="20px"
                  fill="currentColor"
                >
                  <path d="M 24 4 C 20.491685 4 17.570396 6.6214322 17.080078 10 L 10.238281 10 A 1.50015 1.50015 0 0 0 9.9804688 9.9785156 A 1.50015 1.50015 0 0 0 9.7578125 10 L 6.5 10 A 1.50015 1.50015 0 1 0 6.5 13 L 8.6386719 13 L 11.15625 39.029297 C 11.427329 41.835926 13.811782 44 16.630859 44 L 31.367188 44 C 34.186411 44 36.570826 41.836168 36.841797 39.029297 L 39.361328 13 L 41.5 13 A 1.50015 1.50015 0 1 0 41.5 10 L 38.244141 10 A 1.50015 1.50015 0 0 0 37.763672 10 L 30.919922 10 C 30.429604 6.6214322 27.508315 4 24 4 z M 24 7 C 25.879156 7 27.420767 8.2681608 27.861328 10 L 20.138672 10 C 20.579233 8.2681608 22.120844 7 24 7 z M 11.650391 13 L 36.347656 13 L 33.855469 38.740234 C 33.730439 40.035363 32.667963 41 31.367188 41 L 16.630859 41 C 15.331937 41 14.267499 40.033606 14.142578 38.740234 L 11.650391 13 z M 20.476562 17.978516 A 1.50015 1.50015 0 0 0 19 19.5 L 19 34.5 A 1.50015 1.50015 0 1 0 22 34.5 L 22 19.5 A 1.50015 1.50015 0 0 0 20.476562 17.978516 z M 27.476562 17.978516 A 1.50015 1.50015 0 0 0 26 19.5 L 26 34.5 A 1.50015 1.50015 0 1 0 29 34.5 L 29 19.5 A 1.50015 1.50015 0 0 0 27.476562 17.978516 z" />
                </svg>
              </button>
              <span className="ml-4">{a.name}</span>
              <span className="text-gray-500 group-hover:text-primary">
                , {t('mqtt.mqtt_form.endpoint')}: {a.endpoint}:{a.port}
                {a.authentication && ', ' + t('mqtt.mqtt_form.authentication')}
                {a.tls && ', tls'}
                {a.websocket && ', ' + t('mqtt.mqtt_form.websocket')}
              </span>
              {a.brokerIsDefault && (
                <span className="text-primary ml-1 group-hover:text-primary">
                  ({t('mqtt.mqtt_form.default')})
                </span>
              )}
            </li>
          ))}
        </ul>
      )}

      <div className="my-8 text-left">
        <label htmlFor="form-imei">
          {t('mqtt.mqtt_form.label_description')}
        </label>
        <div className="grid grid-cols-12 join join-horizontal">
          <div
            className="uk-input uk-form-large uk-background-muted 
          col-span-2 join-item flex items-center justify-center
          select-none text-gray-500 text-[18px] text-xl"
          >
            {customerId + '-'}
          </div>
          <FormInput
            validationFct={validateLabel}
            isValidated={labelValidated}
            setValidated={setLabelValidated}
            setEmpty={setNameEmpty}
            isEmpty={nameEmpty}
            input={name}
            type="text"
            className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                join-item
                "
            wrapperClassName="col-span-10"
            placeholder={t('mqtt.mqtt_form.label')}
            autoComplete="off"
            required
            maxLength={36}
            autoFocus
            onChange={(e: any) => setName(e.currentTarget.value)}
          />
        </div>
      </div>

      <div className="grid grid-cols-12 gap-4">
        <div className="my-8 text-left col-span-10">
          <label htmlFor="form-imei">{t('mqtt.mqtt_form.host_address')}</label>
          <div className="grid grid-cols-12 join join-horizontal">
            <FormInput
              validationFct={validateMqttHost}
              isValidated={hostValidated}
              setValidated={setHostValidated}
              setEmpty={setHostEmpty}
              isEmpty={hostEmpty}
              input={endpoint}
              type="text"
              className="
          uk-input
          as-input
          uk-form-large
          uk-text-center
          uk-background-muted
          join-item
        "
              wrapperClassName="col-span-12"
              placeholder={t('mqtt.mqtt_form.endpoint_placeholder')}
              autoComplete="off"
              required
              maxLength={36}
              onChange={(e: any) => setEndpoint(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className="my-8 text-left col-span-2">
          <label htmlFor="form-imei">{t('mqtt.mqtt_form.port')}</label>
          <div className="grid grid-cols-12 join join-horizontal">
            <FormInput
              setEmpty={setPortEmpty}
              isEmpty={portEmpty}
              validationFct={validatePort}
              isValidated={portValidated}
              setValidated={setPortValidated}
              input={port}
              type="text"
              className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                join-item
                "
              wrapperClassName="col-span-12"
              placeholder="1883"
              autoComplete="off"
              required={false}
              maxLength={36}
              onChange={(e: any) => setPort(e.currentTarget.value)}
            />
          </div>
        </div>
      </div>
      <div className="-mt-4 mb-6">
        {t('mqtt.mqtt_form.url')}: {websocket ? 'ws' : 'mqtt'}
        {tls && 's'}://{endpoint}:{port}
        {websocket && '/'}
        {websocket && websocketPath}
      </div>

      <div className="w-full flex items-center justify-end">
        <label htmlFor="switch" className="mr-6 cursor-pointer select-none">
          {t('mqtt.mqtt_form.set_as_default_broker')}
        </label>
        <label className="relative inline-flex cursor-pointer items-center">
          <input
            id="switch"
            type="checkbox"
            checked={brokerIsDefault}
            onChange={(e: any) => {
              setBrokerIsDefault(e.target.checked);
            }}
            className="peer sr-only"
          />
          <div className="peer h-4 w-11 rounded-none border bg-slate-200 after:absolute after:-top-1 after:left-0 after:h-6 after:w-6 after:rounded-none after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-primary peer-checked:after:translate-x-full peer-focus:ring-primary"></div>
        </label>
      </div>

      <div className="my-8 text-left col-span-12">
        <label htmlFor="form-imei">{t('mqtt.mqtt_form.client_id')}</label>
        <div className="grid grid-cols-12 join join-horizontal">
          <FormInput
            validationFct={validateClientId}
            isValidated={clientIdValidated}
            setValidated={setClientIdValidated}
            setEmpty={setClientIdEmpty}
            isEmpty={clientIdEmpty}
            input={clientId}
            type="text"
            className="
          uk-input
          as-input
          uk-form-large
          uk-text-center
          uk-background-muted
          join-item
        "
            wrapperClassName="col-span-12"
            placeholder={t('mqtt.mqtt_form.your_client_id')}
            autoComplete="off"
            required
            maxLength={36}
            onChange={(e: any) => setClientId(e.currentTarget.value)}
          />
        </div>
      </div>

      <h3 className="col-span-12 mt-4 -mb-8">
        <b>{t('mqtt.mqtt_form.optional')}</b>
      </h3>

      <div className="my-8 text-left col-span-12">
        <label htmlFor="form-imei">{t('mqtt.mqtt_form.topic_prefix')}</label>
        <div className="grid grid-cols-12 join join-horizontal">
          <FormInput
            validationFct={validatePrefix}
            isValidated={prefixValidated}
            setValidated={setPrefixValidated}
            setEmpty={setPrefixEmpty}
            isEmpty={prefixEmpty}
            input={prefix}
            type="text"
            className="
              uk-input
              as-input
              uk-form-large
              uk-text-center
              uk-background-muted
              join-item
            "
            wrapperClassName="col-span-12"
            placeholder={t('mqtt.mqtt_form.your_topic_prefix')}
            autoComplete="off"
            required
            maxLength={36}
            onChange={(e: any) => setPrefix(e.currentTarget.value)}
          />
        </div>
      </div>

      <Checkbox
        isChecked={tls}
        setIsChecked={setTls}
        label="TLS"
        required={false}
      />
      <Checkbox
        isChecked={websocket}
        setIsChecked={setWebsocket}
        label={t('mqtt.mqtt_form.websocket')}
        required={false}
      />

      {websocket && (
        <div className="bg-secondary/20 px-8 py-2 ml-10 mb-4">
          <div className="my-8 text-left">
            <label htmlFor="form-imei">
              {t('mqtt.mqtt_form.websocket_path')}
            </label>
            <div className="grid grid-cols-12 join join-horizontal">
              <FormInput
                setEmpty={setWebsocketPathEmpty}
                isEmpty={websocketPathEmpty}
                validationFct={validateWebSocketPath}
                isValidated={websocketPathValidated}
                setValidated={setWebsocketPathValidated}
                input={websocketPath}
                type="text"
                className="
                  uk-input
                  as-input
                  uk-form-large
                  uk-text-center
                  uk-background-muted
                  join-item
                  "
                wrapperClassName="col-span-12"
                placeholder={t('mqtt.mqtt_form.your_websocket_path')}
                autoComplete="off"
                required={websocket}
                maxLength={36}
                onChange={(e: any) => setWebsocketPath(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
      )}

      <Checkbox
        isChecked={authentication}
        setIsChecked={setAuthentication}
        label={t('mqtt.mqtt_form.use_authentication')}
        required={false}
      />

      {authentication && (
        <div className="bg-secondary/20 px-8 py-2 ml-10">
          <div className="my-8 text-left">
            <label htmlFor="form-imei">{t('mqtt.mqtt_form.username')}</label>
            <div className="grid grid-cols-12 join join-horizontal">
              <FormInput
                setEmpty={setUsernameEmpty}
                isEmpty={usernameEmpty}
                validationFct={validateUsername}
                isValidated={usernameValidated}
                setValidated={setUsernameValidated}
                input={username}
                type="text"
                className="
                    uk-input
                    as-input
                    uk-form-large
                    uk-text-center
                    uk-background-muted
                    join-item
                    "
                wrapperClassName="col-span-12"
                placeholder={t('mqtt.mqtt_form.your_username')}
                autoComplete="off"
                required
                maxLength={36}
                onChange={(e: any) => setUsername(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className="my-8 text-left">
            <label htmlFor="form-imei">{t('mqtt.mqtt_form.password')}</label>
            <div className="grid grid-cols-12 join join-horizontal">
              <FormInput
                setEmpty={setPasswordEmpty}
                isEmpty={passwordEmpty}
                validationFct={validatePassword}
                isValidated={passwordValidated}
                setValidated={setPasswordValidated}
                input={password}
                type="password"
                className="
                    uk-input
                    as-input
                    uk-form-large
                    uk-text-center
                    uk-background-muted
                    join-item
                    "
                wrapperClassName="col-span-12"
                placeholder={t('mqtt.mqtt_form.password_placeholder')}
                autoComplete="password"
                required
                maxLength={36}
                onChange={(e: any) => setPassword(e.currentTarget.value)}
              />
            </div>
          </div>
        </div>
      )}
      <br />
      <br />
      <h3 className="col-span-12 mt-4">
        <b> {t('mqtt.mqtt_form.add_more_minions')}</b>
      </h3>

      <div className="mb-8 text-left">
        <label htmlFor="form-imei">
          {t('mqtt.mqtt_form.enter_other_imei')}
        </label>
        <div className="grid grid-cols-12 join join-horizontal">
          <FormInput
            setEmpty={setAdditionalImeiEmpty}
            isEmpty={additionalImeiEmpty}
            validationFct={validateImei}
            isValidated={additionalImeiValidated}
            setValidated={setAdditionalImeiValidated}
            input={additionalImei}
            name="url"
            type="text"
            className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                join-item
                "
            wrapperClassName="col-span-11"
            placeholder={t('form.imei.placeholder')}
            autoComplete="off"
            maxLength={36}
            onChange={(e: any) => {
              setAdditionalImei(e.currentTarget.value);
              setAdditionalImeisError('');
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                addToAdditionalIMEIs(additionalImei);
              }
            }}
          />
          <div
            className=" uk-input uk-form-large uk-background-muted 
            col-span-1 join-item cursor-pointer flex items-center justify-center
            select-none text-gray-500 text-[18px]"
            onClick={() => {
              addToAdditionalIMEIs(additionalImei);
            }}
          >
            +
          </div>
        </div>
        {additionalImeisError !== '' && (
          <span className="uk-text-danger mt-4" style={{ fontSize: '90%' }}>
            {additionalImeisError}
          </span>
        )}
      </div>

      <ul className="bg-secondary/20 p-2">
        {additionalImeis?.length >= 4 && (
          <div
            className="text-right text-error italic hover:text-red-600 cursor-pointer"
            onClick={() => {
              setAdditionalImeis([imei]);
              setAdditionalImeisInfo((prev) => {
                return prev.filter((ad: any) => ad.imei === imei);
              });
            }}
          >
            {t('mqtt.mqtt_form.remove_other_devices')}
          </div>
        )}
        {additionalImeis
          .sort((a, b) => (a === imei ? -1 : b === imei ? 1 : 0))
          .map((a, i) => {
            const info = additionalImeisInfo
              .filter((ad: any) => ad.imei === a)
              .slice(-1)[0];
            return (
              <li
                key={i}
                className={`list-disc ml-4 my-1 flex items-center ${
                  a === imei && 'pl-3'
                }`}
              >
                {a !== imei && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setAdditionalImeis((prev) =>
                        prev.filter((_, index) => index !== i)
                      );
                    }}
                    className="text-xl mt-1 uk-text-danger hover:text-font-bold"
                    aria-label={`${t('mqtt.mqtt_form.remove_imei')} ${a}`}
                  >
                    −
                  </button>
                )}
                <span className={`ml-4 ${a === imei && 'text-gray-500'}`}>
                  {a}
                </span>

                <span className="text-gray-500">
                  , {t('mqtt.mqtt_form.currently')}:{' '}
                  {!info || info?.mqttId === null
                    ? t('mqtt.mqtt_form.no_broker_linked')
                    : storedBrokers &&
                      storedBrokers?.filter(
                        (b: any) => b.id === info?.mqttId
                      )[0]?.name}
                </span>
              </li>
            );
          })}
      </ul>

      <div className="col-span-12 flex flex-col justify-center items-start w-full mt-12">
        {formValidated ? (
          <div></div>
        ) : (
          <label className="uk-text-danger" style={{ fontSize: '80%' }}>
            {t('errors.form')}
          </label>
        )}

        <div>
          {loading ? (
            <div>
              <label style={{ fontSize: '80%' }}>
                {t('mqtt.mqtt_form.activation_started')}
              </label>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <button
          type="submit"
          className="btn p-4 btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase as-hide-if-loading disabled:rounded-none disabled:text-white"
          onClick={(e) => handleFormSubmission(e)}
          disabled={loading || !formValidated}
        >
          {loading && <LoadingSpinner />}
          <p>{t('mqtt.mqtt_form.activate_device')}</p>
        </button>
      </div>
    </form>
  );
};

export default CloudToCloudForm;
