import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import Animation from '../animations';
import FormInput from '../formInput';
import LoadingSpinner from '../loadingSpinner';

// modal after successfully activating an cloud to cloud device

interface IDeleteBrokerModal {
  loading: boolean;
  t: any;
  showDeleteBrokerModal: boolean;
  setShowDeleteBrokerModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleBrokerDeletion: (password: String, mqttId: String) => Promise<void>;
  selectedMqttAssignmentId: String;
  deletionError: string;
  setDeletionError: React.Dispatch<React.SetStateAction<string>>;
}

const DeleteBrokerModal: React.FC<IDeleteBrokerModal> = ({
  loading,
  t,
  showDeleteBrokerModal,
  setShowDeleteBrokerModal,
  handleBrokerDeletion,
  selectedMqttAssignmentId,
  deletionError,
  setDeletionError
}) => {
  const modalRef = useRef<HTMLFormElement>(null);
  const [password, setPassword] = useState<string>('');
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  function handleCloseModal() {
    setShowDeleteBrokerModal(false);
  }
  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current?.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (showDeleteBrokerModal) {
      document.body.style.overflow = 'hidden';
      setPasswordEmpty(false);
      setPassword('');
      setDeletionError('');
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showDeleteBrokerModal, setDeletionError]);

  return (
    <Modal
      isOpen={showDeleteBrokerModal}
      overlayClassName="Overlay"
      className="react-modal-success fixed flex justify-center items-center z-50 w-full h-full top-0 left-0 bg-[#354452]/60 outline-none"
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      overlayElement={(props: any, contentElement: any) => (
        <div {...props} onClick={handleOutsideClick}>
          {contentElement}
        </div>
      )}
    >
      <Animation className="flex justify-center items-center">
        <form
          className="w-[420px] max-w-[90%] bg-white"
          ref={modalRef}
          onSubmit={(e: any) => {
            e.preventDefault();
            handleBrokerDeletion(password, selectedMqttAssignmentId);
          }}
        >
          <div className="relative uk-modal-header rounded-none bg-[#D9F2FF]">
            <button
              className="absolute right-4 p-2"
              type="button"
              onClick={handleCloseModal}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="1"
                  y1="1"
                  x2="13"
                  y2="13"
                ></line>
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="13"
                  y1="1"
                  x2="1"
                  y2="13"
                ></line>
              </svg>
            </button>
            <h2 className="uk-modal-title">
              {t('mqtt.form_labels.delete_broker')}
            </h2>
          </div>
          <div
            className="uk-modal-body react-modalcontent rounded-none pb-12"
            uk-overflow-auto="true"
          >
            <span>{t('mqtt.form_labels.confirm_password_to_delete')}</span>
            <div className="my-8 text-left">
              <label htmlFor="form-password">
                {t('mqtt.form_labels.password')}
              </label>
              <div className="grid grid-cols-12 join join-horizontal">
                <FormInput
                  setEmpty={setPasswordEmpty}
                  isEmpty={passwordEmpty}
                  validationFct={() => ({
                    validated: !!password,
                    message: ''
                  })}
                  isValidated={!!password}
                  setValidated={() => {}}
                  input={password}
                  type="password"
                  className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                join-item
                text-[14px]
            "
                  wrapperClassName="col-span-12"
                  placeholder={t('mqtt.form_labels.password_placeholder')}
                  autoComplete="current-password"
                  required
                  maxLength={36}
                  message={password && deletionError}
                  onChange={(e: any) => setPassword(e.currentTarget.value)}
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn p-4 btn-lg btn-error focus:bg-error focus:text-white rounded-none hover:text-white text-white text-2xl uppercase as-hide-if-loading disabled:rounded-none disabled:text-white"
              disabled={!password || loading}
            >
              {loading && <LoadingSpinner />}
              {t('mqtt.form_labels.delete')}
            </button>
          </div>
          <div className="uk-modal-body pt-0 w-full flex justify-end">
            <button
              className="btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase m-0"
              onClick={handleCloseModal}
            >
              {t('success_close')}
            </button>
          </div>
        </form>
      </Animation>
    </Modal>
  );
};

export default DeleteBrokerModal;
