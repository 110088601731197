import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmLogic from './confirmLogic';
import { useNavigate, useSearchParams } from 'react-router-dom';

// this file contains confirm page needed for confirming apollo activation

interface IConfirmPage {
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  currentLanguage: string;
}

const decodeJwt = (token: string) => {
  const payload = token.split('.')[1];
  return JSON.parse(atob(payload));
};

function ConfirmPage({ setLanguage, currentLanguage }: IConfirmPage) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const type = searchParams.get('type');

  // set page title
  useEffect(() => {
    document.title =
      type === 'mqtt'
        ? t('mqtt.password.confirm_mqtt_password')
        : t('form.title_apollo');
  }, [currentLanguage, t, type]);

  useEffect(() => {
    const handlePageLoad = async () => {
      const language = searchParams.get('language');

      if (language) {
        setLanguage(language);
        i18n.changeLanguage(language);
      }

      const token = searchParams.get('token');

      if (token) {
        if (type === 'mqtt') {
          const tokenPayload = decodeJwt(token);
          try {
            setLoading(true);
            let res = await fetch(
              `/api/v1/mqtt/assignment/${tokenPayload.customerId}/register/confirm`,
              {
                method: 'POST',
                headers: new Headers({
                  Authorization: 'Bearer ' + token,
                  'Content-Type': 'application/json'
                }),
                body: JSON.stringify({
                  language: currentLanguage
                })
              }
            );
            setLoading(false);
            if (!res.ok) {
              if (
                res.status === 401 ||
                res.status === 500 ||
                res.status === 404
              ) {
                setSuccess(false);
              }
            } else {
              setSuccess(true);
              navigate('/', {
                state: {
                  customerId: tokenPayload.customerId,
                  imei: tokenPayload.imei,
                  token: token
                }
              });
            }
          } catch (error) {
            setLoading(false);
            setSuccess(false);
            console.log(error);
          }
        } else {
          try {
            setLoading(true);
            let res = await fetch('/api/v1/confirm', {
              method: 'POST',
              headers: new Headers({
                Authorization: 'Bearer ' + token,
                'Content-Type': 'application/json'
              })
            });
            setLoading(false);
            if (!res.ok) {
              if (
                res.status === 401 ||
                res.status === 500 ||
                res.status === 404
              ) {
                setSuccess(false);
              }
            } else {
              setSuccess(true);
            }
          } catch (error) {
            setLoading(false);
            setSuccess(false);
            console.log(error);
          }
        }
      }
    };
    handlePageLoad();
  }, [i18n, searchParams, setLanguage, currentLanguage, navigate, type]);

  return (
    <div className="w-full flex justify-center items-center flex-col p-6">
      <ConfirmLogic
        t={t}
        success={success}
        loading={loading}
        heading={
          type === 'mqtt'
            ? t('mqtt.password.mqtt_password_reset_success_redirect')
            : t('apollo.confirm.heading')
        }
        errorHeading={
          type === 'mqtt'
            ? t('mqtt.errors.confirmation_failed')
            : t('apollo.errors.heading')
        }
        errorText={
          type === 'mqtt'
            ? t('mqtt.errors.password_confirmation_failed')
            : t('apollo.confirm.errorText')
        }
      />
      <div className="w-full text-left">
        <a
          href="/"
          type="submit"
          className="btn p-4btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase"
        >
          <p className="text-2xl">{t('apollo.confirm.button')}</p>
        </a>
      </div>
    </div>
  );
}

export default ConfirmPage;
