import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Animation from '../animations';

// modal after successfully activating an  device

interface ISuccessModal {
  t: any;
  showSuccessModal: boolean;
  setShowSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  successModalText: string;
}

const SuccessModal: React.FC<ISuccessModal> = ({
  t,
  showSuccessModal,
  setShowSuccessModal,
  successModalText
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  function handleCloseModal() {
    setShowSuccessModal(false);
  }
  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current?.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (showSuccessModal) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSuccessModal]);

  return (
    <Modal
      isOpen={showSuccessModal}
      overlayClassName="Overlay"
      className="react-modal-success fixed flex justify-center items-center z-50 w-full h-full top-0 left-0 bg-[#354452]/60 outline-none"
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      overlayElement={(props: any, contentElement: any) => (
        <div {...props} onClick={handleOutsideClick}>
          {contentElement}
        </div>
      )}
    >
      <Animation className="flex justify-center items-center">
        <div className="w-[420px] max-w-[90%] bg-white" ref={modalRef}>
          <div className="relative uk-modal-header rounded-none bg-[#D9F2FF]">
            <button
              className="absolute right-4 p-2"
              type="button"
              onClick={handleCloseModal}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="1"
                  y1="1"
                  x2="13"
                  y2="13"
                ></line>
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="13"
                  y1="1"
                  x2="1"
                  y2="13"
                ></line>
              </svg>
            </button>
            <h2 className="uk-modal-title">{t('success_thankyou')}</h2>
          </div>
          <div
            className="uk-modal-body react-modalcontent rounded-none pb-12"
            uk-overflow-auto="true"
          >
            <div className="mb-12">
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />
                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <div className="my-8">{successModalText}</div>
          </div>
          <div className="uk-modal-body pt-0 w-full flex justify-end">
            <button
              className="btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase m-0"
              onClick={handleCloseModal}
            >
              {t('success_close')}
            </button>
          </div>
        </div>
      </Animation>
    </Modal>
  );
};

export default SuccessModal;
