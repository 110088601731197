import React, { useEffect, useRef } from 'react';
import Modal from 'react-modal';
import Animation from './animations';

// modal after successfully activating an cloud to cloud device

interface ISuccessModalCloudToCloud {
  t: any;
  showSuccessModalCloudToCloud: boolean;
  setShowSuccessModalCloudToCloud: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  successModalCloudToCloudTable: any[];
}

const SuccessModal: React.FC<ISuccessModalCloudToCloud> = ({
  t,
  showSuccessModalCloudToCloud,
  setShowSuccessModalCloudToCloud,
  successModalCloudToCloudTable
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  function handleCloseModal() {
    setShowSuccessModalCloudToCloud(false);
  }
  const handleOutsideClick = (event: any) => {
    if (modalRef.current && !modalRef.current?.contains(event.target)) {
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (showSuccessModalCloudToCloud) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showSuccessModalCloudToCloud]);

  const allSuccess = successModalCloudToCloudTable.every(
    (item) => item.deviceResponse === 200
  );
  const partialFailure = successModalCloudToCloudTable.some(
    (item) => item.deviceResponse === 500
  );

  const message = partialFailure
    ? t('mqtt.errors.minion_link_failed')
    : allSuccess && successModalCloudToCloudTable.length > 1
    ? t('mqtt.errors.minion_link_success_multiple')
    : t('mqtt.errors.minion_link_success_single');

  return (
    <Modal
      isOpen={showSuccessModalCloudToCloud}
      overlayClassName="Overlay"
      className="react-modal-success fixed flex justify-center items-center z-50 w-full h-full top-0 left-0 bg-[#354452]/60 outline-none"
      ariaHideApp={false}
      onRequestClose={handleCloseModal}
      overlayElement={(props: any, contentElement: any) => (
        <div {...props} onClick={handleOutsideClick}>
          {contentElement}
        </div>
      )}
    >
      <Animation className="flex justify-center items-center">
        <div className="w-[420px] max-w-[90%] bg-white" ref={modalRef}>
          <div className="relative uk-modal-header rounded-none bg-[#D9F2FF]">
            <button
              className="absolute right-4 p-2"
              type="button"
              onClick={handleCloseModal}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="1"
                  y1="1"
                  x2="13"
                  y2="13"
                ></line>
                <line
                  fill="none"
                  stroke="#000"
                  strokeWidth="1.1"
                  x1="13"
                  y1="1"
                  x2="1"
                  y2="13"
                ></line>
              </svg>
            </button>
            <h2 className="uk-modal-title">{t('success_thankyou')}</h2>
          </div>
          <div
            className="uk-modal-body react-modalcontent rounded-none pb-12"
            uk-overflow-auto="true"
          >
            <div className="mb-12">
              {partialFailure ? (
                <div className="sweet-alert -mt-8">
                  <div className="icon error animateErrorIcon">
                    <span className="x-mark animateXMark">
                      <span className="line left"></span>
                      <span className="line right"></span>
                    </span>
                  </div>
                </div>
              ) : (
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </div>
            <div className="my-8">{message}</div>
            <div className="w-full mx-auto mt-6">
              <table className="w-full border-collapse border border-gray-300">
                <tbody>
                  {successModalCloudToCloudTable.map((item, index) => (
                    <tr key={index} className="border-b border-gray-300">
                      <td className="p-3 text-left font-mono">{item.imei}</td>
                      <td className="p-3 text-right text-xl">
                        {item.deviceResponse === 200 ? (
                          <span className="text-primary">✔</span>
                        ) : (
                          <span className="text-error">✘</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="uk-modal-body pt-0 w-full flex justify-end">
            <button
              className="btn btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase m-0"
              onClick={handleCloseModal}
            >
              {t('success_close')}
            </button>
          </div>
        </div>
      </Animation>
    </Modal>
  );
};

export default SuccessModal;
