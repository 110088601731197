import { useCallback, useEffect, useState } from 'react';
import FormInput from './formInput';
import LoadingSpinner from './loadingSpinner';
import { validateApolloId } from '../utils/validation';
import { IApolloBody, ICumulocityBody } from '../App';

// appears if customer has entered an IMEI of an apollo device

interface IApolloForm {
  loading: boolean;
  setApolloId: React.Dispatch<React.SetStateAction<string>>;
  handleFormSubmit: (body: IApolloBody | ICumulocityBody) => Promise<void>;
  apolloId: string;
  t: any;
  imei: string;
  customerId: string;
  domain: string;
  currentLanguage: string;
  customerIdValidated: boolean;
  imeiValidated: boolean;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
}

const ApolloForm: React.FC<IApolloForm> = ({
  loading,
  setApolloId,
  handleFormSubmit,
  apolloId,
  imei,
  customerId,
  domain,
  currentLanguage,
  customerIdValidated,
  imeiValidated,
  t,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc
}) => {
  const [apolloIdValidated, setApolloIdValidated] = useState<boolean>(true);
  const [apolloIdEmpty, setApolloIdEmpty] = useState<boolean>(false);
  const [apolloFormValidated, setApolloFormValidated] = useState<boolean>(true);
  const [formFirstRender, setFormFirstRender] = useState<boolean>(true);

  const handleFormValidation = useCallback(() => {
    setFormFirstRender(false);
    let formValid: boolean = true;
    let apolloIdValid: boolean = false;

    // check if values of the starter form lose validation
    if (!customerIdValidated) formValid = false;
    if (!imeiValidated) formValid = false;

    if (apolloId) {
      apolloIdValid = validateApolloId(apolloId).validated;
      setApolloIdValidated(apolloIdValid);
    } else {
      setApolloFormValidated(false);
      setApolloIdEmpty(true);
      return false;
    }

    if (!apolloIdValid) {
      setApolloFormValidated(false);
      formValid = false;
    }

    if (formValid === false) {
      setApolloFormValidated(false);
      return false;
    } else {
      setApolloFormValidated(true);
      return true;
    }
  }, [apolloId, customerIdValidated, imeiValidated]);

  // recheck validation if entered values change
  useEffect(() => {
    if (!formFirstRender) handleFormValidation();
  }, [
    imei,
    customerId,
    apolloId,
    imeiValidated,
    customerIdValidated,
    formFirstRender,
    handleFormValidation
  ]);

  const handleFormSubmission = (e: any) => {
    e.preventDefault();
    const formValid = handleFormValidation();
    if (formValid === true) {
      let body: IApolloBody = {
        imei: imei,
        customerId: customerId,
        domain: domain,
        apolloId: apolloId,
        language: currentLanguage
      };
      handleFormSubmit(body);
    }
  };

  return (
    <form id="apolloForm" className="p-6">
      <div className="w-full grid grid-cols-12 gap-4 p-6">
        <hr className="col-span-12 my-12 w-[80%] relative m-auto left-0 right-0" />
      </div>

      <h3 className="col-span-12">
        <b>{t('apollo.heading')}</b>
      </h3>

      <label htmlFor="form-url">
        {t('apollo.subheading')}
        <br />
      </label>

      <div className="grid grid-cols-12 join join-horizontal">
        <FormInput
          setEmpty={setApolloIdEmpty}
          isEmpty={apolloIdEmpty}
          validationFct={validateApolloId}
          isValidated={apolloIdValidated}
          setValidated={setApolloIdValidated}
          input={apolloId}
          id="form-url"
          name="url"
          type="text"
          className="
          uk-input
          as-input
          uk-form-large
          uk-text-center
          uk-background-muted
          join-item
        "
          wrapperClassName="col-span-11"
          placeholder={t('apollo.placeholder')}
          autoComplete="off"
          required
          maxLength={36}
          pattern="[a-z][a-zA-Z0-9-]*"
          onChange={(e: any) => setApolloId(e.currentTarget.value)}
        />
        <div
          className="uk-input uk-form-large uk-background-muted 
          col-span-1 join-item cursor-pointer flex items-center justify-center
          select-none text-gray-500 text-[18px]"
          onClick={() => {
            setShowSmallprintModal(true);
            setSmallprintModalText(t('apollo.smallprint'));
            setSmallprintModalImageSrc(
              '/img/lang/' + currentLanguage + '/imei.jpg'
            ); // image needs to be changed (LD)
          }}
        >
          &#63;
        </div>
      </div>

      <div className="col-span-12 flex flex-col justify-center items-start w-full mt-12">
        {apolloFormValidated ? (
          <div></div>
        ) : (
          <label className="uk-text-danger" style={{ fontSize: '80%' }}>
            {t('errors.form')}
          </label>
        )}

        <div>
          {loading ? (
            <div>
              <label style={{ fontSize: '80%' }}>{t('form.lineone')}</label>
              <label style={{ fontSize: '80%' }}>{t('form.linetwo')}</label>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <button
          type="submit"
          className="btn p-4 btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase as-hide-if-loading disabled:rounded-none disabled:text-white"
          onClick={(e) => handleFormSubmission(e)}
          disabled={loading}
        >
          {loading && <LoadingSpinner />}
          <p>{t('apollo.button')}</p>
        </button>
      </div>
    </form>
  );
};

export default ApolloForm;
