import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './loadingSpinner';
import FormInput from './formInput';
import { useState } from 'react';
import { validateImei, validateCustomerNr } from '../utils/validation';
import TabNav from './ui/tabNav';
import { useLocation, useNavigate } from 'react-router-dom';

// form needs imei and customer number to show apollo or cumulocity form

interface IStarterForm {
  currentLanguage: string;
  imei?: string;
  setImei: React.Dispatch<React.SetStateAction<string>>;
  customerNr?: string;
  setCustomerNr: React.Dispatch<React.SetStateAction<string>>;
  handleImeiCheck: (imei: string, pToken?: string) => void;
  loading: boolean;
  checkSuccessfull: boolean;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
  imeiValidated: boolean;
  customerNrValidated: boolean;
  setCustomerNrValidated: React.Dispatch<React.SetStateAction<boolean>>;
  setImeiValidated: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'initial' | 'cumulocity' | 'apollo' | 'cloudtocloud';
  formFirstRender: boolean;
  setFormFirstRender: React.Dispatch<React.SetStateAction<boolean>>;
  starterFormValidated: boolean;
  setStarterFormValidated: React.Dispatch<React.SetStateAction<boolean>>;
  setToken: React.Dispatch<React.SetStateAction<String>>;
}

const StarterForm: React.FC<IStarterForm> = ({
  currentLanguage,
  imei,
  setImei,
  customerNr,
  setCustomerNr,
  handleImeiCheck,
  loading,
  checkSuccessfull,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc,
  setCustomerNrValidated,
  setImeiValidated,
  imeiValidated,
  customerNrValidated,
  type,
  formFirstRender,
  setFormFirstRender,
  starterFormValidated,
  setStarterFormValidated,
  setToken
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const payload = location.state;
  const { t } = useTranslation();

  // form validation states
  const [imeiEmpty, setImeiEmpty] = useState<boolean>(false);
  const [customerNrEmpty, setCustomerNrEmpty] = useState<boolean>(false);

  // set page title
  useEffect(() => {
    document.title = t('form.title_cloud');
  }, [currentLanguage, t]);

  const handleFormValidation = useCallback(() => {
    setFormFirstRender(false);
    let formValid: boolean = true;

    let imeiValid: boolean = true;
    let customerNrValid: boolean = true;

    if (customerNr && imei) {
      formValid = true;
      setStarterFormValidated(true);

      imeiValid = validateImei(imei).validated;
      setImeiValidated(imeiValid);

      customerNrValid = validateCustomerNr(customerNr).validated;
      setCustomerNrValidated(customerNrValid);
    } else {
      formValid = false;
      setStarterFormValidated(false);
      if (!customerNr) {
        setCustomerNrEmpty(true);
        setCustomerNrValidated(false);
      }
      if (!imei) {
        setImeiEmpty(true);
        setImeiValidated(false);
      }
    }

    if (!imeiValid || !customerNrValid) {
      formValid = false;
      setStarterFormValidated(false);
    }

    if (formValid === true && imei) {
      return true;
    }
  }, [
    imei,
    customerNr,
    setCustomerNrValidated,
    setImeiValidated,
    setStarterFormValidated,
    setCustomerNrEmpty,
    setImeiEmpty,
    setFormFirstRender
  ]);

  const handleFormSubmission = useCallback(
    (e?: any, pToken?: string) => {
      if (e) e.preventDefault();
      const formValid = handleFormValidation();
      if (formValid === true && imei) {
        handleImeiCheck(imei, pToken);
      }
    },
    [handleFormValidation, imei, handleImeiCheck]
  );

  useEffect(() => {
    if (payload && Object.keys(payload).length >= 1) {
      setCustomerNr(payload.customerId || '');
      setImei(payload.imei || '');
      setToken(payload.token || '');
      handleFormSubmission(null, payload.token);

      navigate('.', { replace: true, state: {} });
    }
  }, [
    payload,
    navigate,
    handleFormSubmission,
    setCustomerNr,
    setImei,
    setToken
  ]);

  // recheck validation if entered values change
  useEffect(() => {
    if (!formFirstRender) handleFormValidation();
  }, [imei, customerNr, formFirstRender, handleFormValidation]);

  return (
    <>
      <div className="w-full flex justify-center items-center bg-[#D9F2FF] h-[68px] p-4">
        <h1 className="text-[2rem] leading-10">{t('form.title_cloud')}</h1>
      </div>

      <TabNav />

      <form className="text-center p-6" onSubmit={handleFormSubmission}>
        <div className="my-8 text-left">
          <label htmlFor="form-imei">{t('form.imei.label')}</label>
          <div className="grid grid-cols-12 join join-horizontal">
            <FormInput
              setEmpty={setImeiEmpty}
              isEmpty={imeiEmpty}
              input={imei}
              isValidated={imeiValidated}
              setValidated={setImeiValidated}
              validationFct={validateImei}
              id="form-imei"
              name="imei"
              type="text"
              className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                w-full
                join-item
                text-[14px]
                "
              wrapperClassName="w-full col-span-11"
              placeholder={t('form.imei.placeholder')}
              autoComplete="off"
              required
              minLength={15}
              maxLength={18}
              disabled={type !== 'initial'}
              onChange={(e: any) => setImei(e.currentTarget.value)}
            />
            <div
              className="uk-input uk-form-large uk-background-muted 
              col-span-1 join-item cursor-pointer flex items-center justify-center
              select-none text-gray-500 text-[18px]"
              onClick={() => {
                setShowSmallprintModal(true);
                setSmallprintModalText(t('form.imei.smallprint'));
                setSmallprintModalImageSrc(
                  '/img/lang/' + currentLanguage + '/imei.jpg'
                );
              }}
            >
              &#63;
            </div>
          </div>
        </div>

        <div className="my-8 text-left">
          <label htmlFor="form-customer-id">{t('form.customer.label')}</label>
          <div className="grid grid-cols-12 join join-horizontal">
            <FormInput
              setEmpty={setCustomerNrEmpty}
              isEmpty={customerNrEmpty}
              isValidated={customerNrValidated}
              setValidated={setCustomerNrValidated}
              validationFct={validateCustomerNr}
              input={customerNr}
              id="form-customer-id"
              name="customerId"
              type="text"
              className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                w-full
                join-item
                text-[14px]
                "
              wrapperClassName="w-full col-span-11"
              placeholder={t('form.customer.placeholder')}
              autoComplete="off"
              required
              minLength={7}
              maxLength={7}
              disabled={type !== 'initial'}
              onChange={(e: any) => setCustomerNr(e.currentTarget.value)}
            />
            <div
              className=" uk-input uk-form-large uk-background-muted 
              col-span-1 join-item cursor-pointer flex items-center justify-center
              select-none text-gray-500 text-[18px]"
              onClick={() => {
                setShowSmallprintModal(true);
                setSmallprintModalText(t('form.customer.smallprint'));
                setSmallprintModalImageSrc(
                  '/img/lang/' + currentLanguage + '/customerid.jpg'
                );
              }}
            >
              &#63;
            </div>
          </div>
        </div>

        {checkSuccessfull ? (
          <></>
        ) : (
          <div className="flex flex-col justify-center items-start width-full">
            {starterFormValidated ? (
              <div></div>
            ) : (
              <label
                className="uk-text-danger  mb-4"
                style={{ fontSize: '90%' }}
              >
                {t('errors.form')}
              </label>
            )}
            <button
              type="submit"
              className="btn p-4 btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase as-hide-if-loading disabled:rounded-none disabled:text-white"
              disabled={loading || !starterFormValidated}
            >
              {loading && <LoadingSpinner />}
              <p className="text-2xl">{t('check_data')}</p>
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default StarterForm;
