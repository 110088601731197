import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../loadingSpinner';
import FormInput from '../formInput';
import { useState } from 'react';
import {
  validateEmail,
  validateCustomerNr,
  validatePhone,
  validateApolloUrl
} from '../../utils/validation';
import RequiredInputField from '../requiredInputField';
import { Link } from 'react-router-dom';
import TabNav from '../ui/tabNav';

// this file contains form for creating / registrating a new apollo container

interface IRegisterForm {
  currentLanguage: string;
  handleFormSubmit: any;
  loading: boolean;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCustomerNr: React.Dispatch<React.SetStateAction<string>>;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setCompany: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  customerNr: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  url: string;
}

const RegisterForm: React.FC<IRegisterForm> = ({
  currentLanguage,
  handleFormSubmit,
  loading,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc,
  setFirstName,
  setLastName,
  setEmail,
  setCompany,
  setPhone,
  setCustomerNr,
  setUrl,
  customerNr,
  email,
  firstName,
  lastName,
  company,
  phone,
  url
}) => {
  const [registrationFormValidated, setRegistrationFormValidated] =
    useState<boolean>(true);
  const [formFirstRender, setFormFirstRender] = useState<boolean>(true);

  // email, customerId, firstName, lastName, comapny, phone, language, domain
  const [emailValidated, setEmailValidated] = useState<boolean>(true);
  const [emailEmpty, setEmailEmpty] = useState<boolean>(false);
  const [customerNrValidated, setCustomerNrValidated] = useState<boolean>(true);
  const [customerNrEmpty, setCustomerNrEmpty] = useState<boolean>(false);
  const [phoneValidated, setPhoneValidated] = useState<boolean>(true);
  const [phoneEmpty, setPhoneEmpty] = useState<boolean>(false);
  const [firstNameEmpty, setFirstNameEmpty] = useState<boolean>(false);
  const [lastNameEmpty, setLastNameEmpty] = useState<boolean>(false);
  const [companyEmpty, setCompanyEmpty] = useState<boolean>(false);
  const [domainValidated, setDomainValidated] = useState<boolean>(true);
  const [domainEmpty, setDomainEmpty] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleFormValidation = useCallback(() => {
    setFormFirstRender(false);
    let formValid = true;
    let customerNrValid = false;
    let emailValid = false;
    let phoneValid = false;
    let urlValid = false;

    // If nothing is empty
    if (customerNr && email && firstName && lastName && phone && company) {
      // Validate values
      emailValid = validateEmail(email).validated;
      setEmailValidated(emailValid);

      phoneValid = validatePhone(phone).validated;
      setPhoneValidated(phoneValid);

      customerNrValid = validateCustomerNr(customerNr).validated;
      setCustomerNrValidated(customerNrValid);

      urlValid = validateApolloUrl(url).validated;
      setDomainValidated(urlValid);

      // If values are not valid
      if (!phoneValid || !emailValid || !customerNrValid || !urlValid) {
        formValid = false;
      }
    } else {
      formValid = false;

      if (!email) setEmailEmpty(true);
      if (!customerNr) setCustomerNrEmpty(true);
      if (!firstName) setFirstNameEmpty(true);
      if (!lastName) setLastNameEmpty(true);
      if (!company) setCompanyEmpty(true);
      if (!phone) setPhoneEmpty(true);
      if (!url) setDomainEmpty(true);
    }

    if (!formValid) {
      setRegistrationFormValidated(false);
      return false;
    } else {
      setRegistrationFormValidated(true);
      return true;
    }
  }, [customerNr, email, firstName, lastName, phone, company, url]);

  const handleFormSubmission = (e: any) => {
    e.preventDefault();
    const formValid = handleFormValidation();
    if (formValid) handleFormSubmit();
  };

  // recheck validation if entered values change
  useEffect(() => {
    if (!formFirstRender) handleFormValidation();
  }, [
    customerNr,
    email,
    firstName,
    lastName,
    phone,
    company,
    url,
    formFirstRender,
    handleFormValidation
  ]);

  return (
    <>
      <div className="w-full flex justify-center items-center bg-[#D9F2FF] h-[68px] p-4">
        <h1 className="text-[2rem] leading-10">
          {t('form.title_apollo_signup')}
        </h1>
      </div>

      <TabNav />

      <form id="registration" className="p-6">
        <div
          role="alert"
          className="alert border-none bg-[#e6e6e6] relative right-0 text-[14px] rounded-none flex justify-between mt-4"
        >
          <span className="text-neutral">
            {t('apollo.signup.register_request')}
          </span>
          <div className="float-right">
            <Link
              to="/apollo"
              className="btn btn-lg btn-primary focus:bg-primary text-xl text-white rounded-none leading-5"
              data-testid="activate_button"
            >
              {t('apollo.signup.register_prompt')}
            </Link>
          </div>
        </div>

        <div className="text-left col-span-12 my-4">
          <label htmlFor="form-customer-id">{t('form.customer.label')}</label>
          <div className="grid grid-cols-12 join join-horizontal">
            <FormInput
              setEmpty={setCustomerNrEmpty}
              isEmpty={customerNrEmpty}
              isValidated={customerNrValidated}
              setValidated={setCustomerNrValidated}
              validationFct={validateCustomerNr}
              input={customerNr}
              id="form-customer-id"
              name="customerId"
              type="text"
              className="
                uk-input
                as-input
                uk-form-large
                uk-text-center
                uk-background-muted
                w-full
                join-item
                "
              wrapperClassName="col-span-11"
              placeholder={t('form.customer.placeholder')}
              autoComplete="off"
              required
              minLength={7}
              maxLength={7}
              onChange={(e: any) => setCustomerNr(e.currentTarget.value)}
            />
            <div
              className=" uk-input uk-form-large uk-background-muted 
              col-span-1 join-item cursor-pointer flex items-center justify-center
              select-none text-gray-500 text-[18px]"
              data-testid="activate_customernr_smallprint"
              onClick={() => {
                setShowSmallprintModal(true);
                setSmallprintModalText(t('form.customer.smallprint'));
                setSmallprintModalImageSrc(
                  '/img/lang/' + currentLanguage + '/customerid.jpg'
                );
              }}
            >
              &#63;
            </div>
          </div>
        </div>

        <div className="my-8 text-left">
          <label htmlFor="form-customer-id">{t('apollo.form.url.label')}</label>
          <FormInput
            setEmpty={setDomainEmpty}
            isEmpty={domainEmpty}
            isValidated={domainValidated}
            setValidated={setDomainValidated}
            validationFct={validateApolloUrl}
            input={url}
            id="form-domain"
            name="domain"
            type="text"
            className="
              uk-input
              as-input
              uk-form-large
              uk-text-center
              uk-background-muted
              w-full
              join-item
              "
            wrapperClassName="w-full col-span-12"
            placeholder={t('apollo.form.url.placeholder')}
            autoComplete="off"
            required
            minLength={3}
            maxLength={50}
            onChange={(e: any) => setUrl(e.currentTarget.value)}
          />
        </div>

        <hr className="col-span-12 my-12 w-[80%] relative m-auto left-0 right-0" />

        <div className="w-full grid grid-cols-12 gap-4">
          <RequiredInputField
            setEmpty={setFirstNameEmpty}
            isEmpty={firstNameEmpty}
            input={firstName}
            name="firstName"
            type="text"
            className="
            uk-input
            as-input
            uk-form-large
            uk-background-muted
          "
            placeholder={t('form.first_name')}
            wrapperClassName="col-span-12 sm:col-span-6 sm:col-start-1"
            autoComplete="off"
            required
            maxLength={20}
            pattern="[a-zA-Z]*"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setFirstName(e.currentTarget.value)
            }
          />

          <RequiredInputField
            input={lastName}
            setEmpty={setLastNameEmpty}
            isEmpty={lastNameEmpty}
            name="lastName"
            type="text"
            className="
            uk-input
            as-input
            uk-form-large  
            uk-background-muted
          "
            wrapperClassName="col-span-12 sm:col-span-6"
            placeholder={t('form.last_name')}
            autoComplete="off"
            required
            maxLength={20}
            pattern="[a-zA-Z]*"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setLastName(e.currentTarget.value)
            }
          />

          <FormInput
            setEmpty={setEmailEmpty}
            isEmpty={emailEmpty}
            input={email}
            isValidated={emailValidated}
            setValidated={setEmailValidated}
            validationFct={validateEmail}
            style={{ letterSpacing: '.6px' }}
            name="email"
            type="email"
            className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
            wrapperClassName="col-span-12"
            placeholder={t('apollo.form.email.placeholder')}
            autoComplete="off"
            required
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setEmail(e.currentTarget.value)
            }
          />

          <FormInput
            isValidated={phoneValidated}
            setValidated={setPhoneValidated}
            validationFct={validatePhone}
            input={phone}
            setEmpty={setPhoneEmpty}
            isEmpty={phoneEmpty}
            name="phone"
            type="tel"
            className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
            wrapperClassName="col-span-12 sm:col-span-6"
            placeholder={t('form.phone')}
            autoComplete="off"
            required
            maxLength={20}
            pattern="[+](([(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[\s\.]?[(]?[0-9]{1,3}[)]?([\s\.]?[0-9]{3})([\s\.]?[0-9]{3,4})"
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setPhone(e.currentTarget.value)
            }
          />

          <RequiredInputField
            input={company}
            setEmpty={setCompanyEmpty}
            isEmpty={companyEmpty}
            name="company"
            type="text"
            className="
              uk-input
              as-input
              uk-form-large
              uk-background-muted
            "
            wrapperClassName="col-span-12 sm:col-span-6"
            placeholder={t('form.company')}
            autoComplete="off"
            maxLength={256}
            required
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              setCompany(e.currentTarget.value)
            }
          />
        </div>

        <div className="flex flex-col justify-center items-start width-full mt-8">
          {registrationFormValidated ? (
            <div></div>
          ) : (
            <label className="uk-text-danger mb-4" style={{ fontSize: '90%' }}>
              {t('apollo.errors.general')}
            </label>
          )}
          <button
            type="submit"
            data-testid="signup_submit_button"
            className="btn p-4 btn-lg btn-primary focus:bg-primary rounded-none text-white text-2xl uppercase as-hide-if-loading disabled:rounded-none disabled:text-white"
            onClick={(e) => handleFormSubmission(e)}
            disabled={loading || !registrationFormValidated}
          >
            {loading && <LoadingSpinner />}
            <p className="text-2xl">{t('apollo.signup.signup_button')}</p>
          </button>
        </div>
      </form>
    </>
  );
};

export default RegisterForm;
