import React, { useEffect } from 'react';
import ApolloForm from './apolloForm';
import ContactDataForm from './contactDataForm';
import { IApolloBody, ICloudToCloudBody, ICumulocityBody } from '../App';
import CloudToCloudForm, {
  MqttLoginForm,
  RegisterMqttPasswordForm
} from './cloudtocloudForm';

// this components distinguished between apollo or cumulocity form

interface IFormLogic {
  loading: boolean;
  type: string | null;
  setSalutation: React.Dispatch<React.SetStateAction<string>>;
  setSalutationCode: React.Dispatch<
    React.SetStateAction<'male' | 'female' | 'other'>
  >;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  setCompany: React.Dispatch<React.SetStateAction<string>>;
  setIndustry: React.Dispatch<React.SetStateAction<string>>;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  setStreet: React.Dispatch<React.SetStateAction<string>>;
  setPostcode: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  setPasswordConfirm: React.Dispatch<React.SetStateAction<string>>;
  setAcceptToc: React.Dispatch<React.SetStateAction<boolean>>;
  setAcceptNewsletter: React.Dispatch<React.SetStateAction<boolean>>;
  acceptToc: boolean;
  acceptNewsletter: boolean;
  setErrorModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<
    React.SetStateAction<'initial' | 'cumulocity' | 'apollo' | 'cloudtocloud'>
  >;
  setErrorMessageBody: React.Dispatch<React.SetStateAction<string>>;
  t: any;
  setImeiCheckSuccessfull: React.Dispatch<React.SetStateAction<boolean>>;
  setApolloId: React.Dispatch<React.SetStateAction<string>>;
  handleFormSubmit: (
    body: IApolloBody | ICumulocityBody | ICloudToCloudBody
  ) => Promise<void>;
  apolloId: string;
  email: string;
  password: string;
  passwordConfirm: string;
  salutation: string;
  salutationCode: string;
  firstName: string;
  lastName: string;
  company: string;
  industry: string;
  phone: string;
  street: string;
  postcode: string;
  city: string;
  currentLanguage: string;
  url: string;
  setUrl: React.Dispatch<React.SetStateAction<string>>;
  imei: string;
  customerId: string;
  handleNewsletterSubscription: () => Promise<void>;
  handlePasswordRegistration: (
    password: String,
    email: String
  ) => Promise<void>;
  handlePasswordReset: (email: String) => Promise<void>;
  handleMqttLogin: (password: String) => Promise<void>;
  setShowSmallprintModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSmallprintModalText: React.Dispatch<React.SetStateAction<string>>;
  setSmallprintModalImageSrc: React.Dispatch<React.SetStateAction<string>>;
  customerIdValidated: boolean;
  imeiValidated: boolean;
  handleUrlCheck: (url: string) => Promise<boolean>;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  endpoint: string;
  setEndpoint: React.Dispatch<React.SetStateAction<string>>;
  port: string;
  setPort: React.Dispatch<React.SetStateAction<string>>;
  tls: boolean;
  setTls: React.Dispatch<React.SetStateAction<boolean>>;
  websocketPath: string;
  setWebsocketPath: React.Dispatch<React.SetStateAction<string>>;
  authentication: boolean;
  setAuthentication: React.Dispatch<React.SetStateAction<boolean>>;
  websocket: boolean;
  setWebsocket: React.Dispatch<React.SetStateAction<boolean>>;
  username: string;
  setUsername: React.Dispatch<React.SetStateAction<string>>;
  additionalImeis: string[];
  setAdditionalImeis: React.Dispatch<React.SetStateAction<string[]>>;
  clientId: string;
  setClientId: React.Dispatch<React.SetStateAction<string>>;
  prefix: string;
  setPrefix: React.Dispatch<React.SetStateAction<string>>;
  hasMqttAuthentication: boolean;
  setHasMqttAuthentication: React.Dispatch<React.SetStateAction<boolean>>;
  token: String;
  setToken: React.Dispatch<React.SetStateAction<String>>;
  storedBrokers: any[];
  setStoredBrokers: React.Dispatch<React.SetStateAction<any[]>>;
  setMqttIdToDelete: React.Dispatch<React.SetStateAction<string>>;
  setShowDeleteBrokerModal: React.Dispatch<React.SetStateAction<boolean>>;
  mqttIdToDelete: string;
  additionalImeisInfo: any[];
  setAdditionalImeisInfo: React.Dispatch<React.SetStateAction<any[]>>;
  brokerIsDefault: boolean;
  setBrokerIsDefault: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormLogic: React.FC<IFormLogic> = ({
  loading,
  type,
  setSalutation,
  setSalutationCode,
  setFirstName,
  setLastName,
  setEmail,
  setCompany,
  setIndustry,
  setPhone,
  setStreet,
  setPostcode,
  setCity,
  setPassword,
  setPasswordConfirm,
  setAcceptToc,
  setAcceptNewsletter,
  acceptToc,
  acceptNewsletter,
  setErrorModalOpen,
  setErrorMessageBody,
  setType,
  setImeiCheckSuccessfull,
  setApolloId,
  t,
  handleFormSubmit,
  apolloId,
  email,
  password,
  passwordConfirm,
  salutation,
  salutationCode,
  firstName,
  lastName,
  company,
  industry,
  phone,
  street,
  postcode,
  city,
  currentLanguage,
  url,
  setUrl,
  imei,
  customerId,
  handleNewsletterSubscription,
  setShowSmallprintModal,
  setSmallprintModalText,
  setSmallprintModalImageSrc,
  customerIdValidated,
  imeiValidated,
  handleUrlCheck,
  name,
  setName,
  endpoint,
  setEndpoint,
  port,
  setPort,
  tls,
  setTls,
  websocketPath,
  setWebsocketPath,
  authentication,
  setAuthentication,
  username,
  setUsername,
  additionalImeis,
  setAdditionalImeis,
  prefix,
  setPrefix,
  clientId,
  setClientId,
  hasMqttAuthentication,
  token,
  handlePasswordRegistration,
  handlePasswordReset,
  handleMqttLogin,
  websocket,
  setWebsocket,
  setMqttIdToDelete,
  setShowDeleteBrokerModal,
  storedBrokers,
  setStoredBrokers,
  mqttIdToDelete,
  additionalImeisInfo,
  setAdditionalImeisInfo,
  brokerIsDefault,
  setBrokerIsDefault
}) => {
  useEffect(() => {
    if (type === null) {
      setImeiCheckSuccessfull(false);
      setType('initial');
      setErrorMessageBody(t('errors.imei'));
      setErrorModalOpen(true);
    } else if (type !== 'initial') {
      setImeiCheckSuccessfull(true);
    }
  }, [
    type,
    setErrorMessageBody,
    setErrorModalOpen,
    setImeiCheckSuccessfull,
    setType,
    t
  ]);

  if (type !== 'initial') {
    if (type === 'cloudtocloud') {
      if (hasMqttAuthentication) {
        if (token) {
          return (
            <CloudToCloudForm
              loading={loading}
              customerId={customerId}
              currentLanguage={currentLanguage}
              t={t}
              imei={imei}
              name={name}
              setName={setName}
              endpoint={endpoint}
              setEndpoint={setEndpoint}
              port={port}
              setPort={setPort}
              tls={tls}
              setTls={setTls}
              websocketPath={websocketPath}
              setWebsocketPath={setWebsocketPath}
              authentication={authentication}
              setAuthentication={setAuthentication}
              username={username}
              setUsername={setUsername}
              password={password}
              setPassword={setPassword}
              additionalImeis={additionalImeis}
              setAdditionalImeis={setAdditionalImeis}
              imeiValidated={imeiValidated}
              customerIdValidated={customerIdValidated}
              handleFormSubmit={handleFormSubmit}
              prefix={prefix}
              setPrefix={setPrefix}
              clientId={clientId}
              setClientId={setClientId}
              websocket={websocket}
              setWebsocket={setWebsocket}
              setMqttIdToDelete={setMqttIdToDelete}
              setShowDeleteBrokerModal={setShowDeleteBrokerModal}
              storedBrokers={storedBrokers}
              setStoredBrokers={setStoredBrokers}
              token={token}
              mqttIdToDelete={mqttIdToDelete}
              additionalImeisInfo={additionalImeisInfo}
              setAdditionalImeisInfo={setAdditionalImeisInfo}
              brokerIsDefault={brokerIsDefault}
              setBrokerIsDefault={setBrokerIsDefault}
            />
          );
        } else {
          return (
            <MqttLoginForm
              t={t}
              loading={loading}
              handleMqttLogin={handleMqttLogin}
              handlePasswordReset={handlePasswordReset}
              imeiValidated={imeiValidated}
              customerIdValidated={customerIdValidated}
            />
          );
        }
      } else
        return (
          <RegisterMqttPasswordForm
            t={t}
            loading={loading}
            handlePasswordRegistration={handlePasswordRegistration}
            imeiValidated={imeiValidated}
            customerIdValidated={customerIdValidated}
          />
        );
    } else if (type === 'apollo') {
      return (
        <ApolloForm
          domain={url}
          currentLanguage={currentLanguage}
          imei={imei}
          customerId={customerId}
          t={t}
          apolloId={apolloId}
          setApolloId={setApolloId}
          loading={loading}
          handleFormSubmit={handleFormSubmit}
          imeiValidated={imeiValidated}
          customerIdValidated={customerIdValidated}
          setShowSmallprintModal={setShowSmallprintModal}
          setSmallprintModalImageSrc={setSmallprintModalImageSrc}
          setSmallprintModalText={setSmallprintModalText}
        />
      );
    } else if (type === 'cumulocity') {
      return (
        <ContactDataForm
          handleNewsletterSubscription={handleNewsletterSubscription}
          imei={imei}
          customerId={customerId}
          email={email}
          password={password}
          passwordConfirm={passwordConfirm}
          setSalutation={setSalutation}
          setSalutationCode={setSalutationCode}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setEmail={setEmail}
          setCompany={setCompany}
          setIndustry={setIndustry}
          setPhone={setPhone}
          setStreet={setStreet}
          setPostcode={setPostcode}
          setCity={setCity}
          setPassword={setPassword}
          setPasswordConfirm={setPasswordConfirm}
          setAcceptToc={setAcceptToc}
          setAcceptNewsletter={setAcceptNewsletter}
          acceptToc={acceptToc}
          acceptNewsletter={acceptNewsletter}
          handleFormSubmit={handleFormSubmit}
          loading={loading}
          salutation={salutation}
          salutationCode={salutationCode}
          firstName={firstName}
          lastName={lastName}
          company={company}
          industry={industry}
          phone={phone}
          street={street}
          postcode={postcode}
          city={city}
          currentLanguage={currentLanguage}
          url={url}
          setUrl={setUrl}
          setShowSmallprintModal={setShowSmallprintModal}
          setSmallprintModalImageSrc={setSmallprintModalImageSrc}
          setSmallprintModalText={setSmallprintModalText}
          imeiValidated={imeiValidated}
          customerIdValidated={customerIdValidated}
          handleUrlCheck={handleUrlCheck}
        />
      );
    } else if (type === null) {
      return <div></div>;
    }
  }
  return <div></div>;
};

export default FormLogic;
