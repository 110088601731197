import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavBar from './components/ui/navbar';
import StarterForm from './components/starterForm';
import ErrorModal from './components/errorModal';
import SuccessModalApollo from './components/successModalApollo';
import SuccessModalCloudToCloud from './components/successModalCloudToCloud';
import SuccessModalCumulocity from './components/successModalCumulocity';
import ImprintModal from './components/imprintModal';
import FormLogic from './components/formLogic';
import Step from './components/step';
import RegistrationPage from './components/registration/registrationPage';
import ActivateApolloPage from './components/registration/activateApolloPage';
import ConfirmPage from './components/registration/confirmPage';
import SmallprintModal from './components/smallprintModal';
import Animation from './components/animations';
import ConfirmSignupPage from './components/registration/confirmSignupPage';
import DeleteBrokerModal from './components/modals/deleteBrokerModal';
import ResetMqttPasswordHandlerPage from './components/handlerPages/confirmMqttPasswordReset';
import SuccessModal from './components/modals/successModal';

export interface IApolloBody {
  imei: String;
  customerId: String;
  domain: String;
  apolloId: String;
  language: String;
}

export interface ICloudToCloudBody {
  imei: String;
  customerId: String;
  language: String;
  additionalImeis: string[];
  connectionData: {
    clientId: String;
    endpoint: String;
    password: String | null;
    path: string | null;
    port: Number;
    prefix: String;
    tls: boolean;
    username: String | null;
    websocket: boolean;
    authentication: boolean;
  };
  name: String;
  brokerIsDefault: boolean;
}

export interface ICumulocityBody {
  imei: string;
  customerId: string;
  domain: string;
  email: string;
  salutation: string;
  salutationCode: string;
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  street: string;
  postcode: string;
  city: string;
  industry: string;
  password: string;
  language: string;
}

function App() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setLanguage] = useState<string>('en');
  const [imprintIsOpen, setImprintOpen] = useState<boolean>(false);
  const [imeiCheckSuccessfull, setImeiCheckSuccessfull] =
    useState<boolean>(false);

  useEffect(() => {
    const browserLanguage = navigator.language.trim().split(/-|_/)[0];
    const possibleLanguages = ['en', 'es', 'cz', 'de', 'nl', 'fr', 'it'];
    if (possibleLanguages.includes(browserLanguage)) {
      setLanguage(browserLanguage);
      i18n.changeLanguage(browserLanguage);
    }
  }, [i18n]);

  const [type, setType] = useState<
    'initial' | 'cumulocity' | 'apollo' | 'cloudtocloud'
  >('initial'); // initial, apollo or cumulocity
  const [article, setArticle] = useState<string | null>('io-key');
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [successModalText, setSuccessModalText] = useState<string>('');
  const [showSuccessModalApollo, setShowSuccessModalApollo] =
    useState<boolean>(false);
  const [showSuccessModalCloudToCloud, setShowSuccessModalCloudToCloud] =
    useState<boolean>(false);
  const [successModalCloudToCloudTable, setSuccessModalCloudToCloudTable] =
    useState<any[]>([]);
  const [showSuccessModalCumulocity, setShowSuccessModalCumulocity] =
    useState<boolean>(false);
  const [showSmallprintModal, setShowSmallprintModal] =
    useState<boolean>(false);
  const [smallprintModalText, setSmallprintModalText] = useState<string>('');
  const [smallprintModalImageSrc, setSmallprintModalImageSrc] =
    useState<string>('');
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [showDeleteBrokerModal, setShowDeleteBrokerModal] =
    useState<boolean>(false);
  const [mqttIdToDelete, setMqttIdToDelete] = useState<string>('');

  // error modal
  const [errorModalIsOpen, setErrorModalOpen] = useState<boolean>(false);
  const errorMessageHeading = t('errors.heading');
  const [errorMessageBody, setErrorMessageBody] = useState<string>('');

  // state starter form
  const [url, setUrl] = useState<string>('');
  const [imei, setImei] = useState<string>(
    process.env.REACT_APP_DEV_IMEI || ''
  );
  const [customerNr, setCustomerNr] = useState<string>(
    process.env.REACT_APP_DEV_CUSTOMER_ID || ''
  );
  const [starterFormFirstRender, setStarterFormFirstRender] =
    useState<boolean>(true);
  const [starterFormValidated, setStarterFormValidated] =
    useState<boolean>(true);

  // state contact form
  const [salutation, setSalutation] = useState<string>('');
  const [salutationCode, setSalutationCode] = useState<
    'male' | 'female' | 'other'
  >('other');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [street, setStreet] = useState<string>('');
  const [postcode, setPostcode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [acceptToc, setAcceptToc] = useState<boolean>(false);
  const [acceptNewsletter, setAcceptNewsletter] = useState<boolean>(false);
  const [customerNrValidated, setCustomerNrValidated] = useState<boolean>(true);
  const [imeiValidated, setImeiValidated] = useState<boolean>(true);

  // state apollo form
  const [apolloId, setApolloId] = useState<string>('');

  // cloudtocloud form
  const [storedBrokers, setStoredBrokers] = useState<any[]>([]);
  const [deletionError, setDeletionError] = useState<string>('');
  const [hasMqttAuthentication, setHasMqttAuthentication] =
    useState<boolean>(false);
  const [token, setToken] = useState<String>('');
  const [name, setName] = useState<string>('');
  const [endpoint, setEndpoint] = useState<string>('');
  const [port, setPort] = useState<string>('1883');
  const [prefix, setPrefix] = useState<string>('');
  const [clientId, setClientId] = useState<string>('');
  const [tls, setTls] = useState<boolean>(false);
  const [websocketPath, setWebsocketPath] = useState<string>('');
  const [authentication, setAuthentication] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [additionalImeis, setAdditionalImeis] = useState<string[]>([]);
  const [websocket, setWebsocket] = useState<boolean>(false);
  const [originalBroker, setOriginalBroker] = useState<any>({});
  const [additionalImeisInfo, setAdditionalImeisInfo] = useState<any[]>([]);
  const [brokerIsDefault, setBrokerIsDefault] = useState<boolean>(false);

  const resetMqttInputs = () => {
    setName('');
    setEndpoint('');
    setPort('1883');
    setClientId('');
    setPrefix('');
    setTls(false);
    setWebsocket(false);
    setWebsocketPath('');
    setAuthentication(false);
    setUsername('');
    setPassword('');
    setAdditionalImeis([imei]);
  };

  const resetForm = () => {
    setType('initial');
    setImei('');
    setImeiCheckSuccessfull(false);
    setStarterFormFirstRender(true);
    setStarterFormValidated(true);
    setCustomerNr('');
    setSalutation('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setCompany('');
    setIndustry('');
    setPhone('');
    setStreet('');
    setPostcode('');
    setCity('');
    setPassword('');
    setPasswordConfirm('');
    setAcceptToc(false);
    setAcceptNewsletter(false);
    setCustomerNrValidated(true);
    setImeiValidated(true);
    resetMqttInputs();
  };

  const checkForChangesInMqttBody = (originalBody: any, body: any) => {
    const originalData = originalBody.mqttAssignment;
    const newData = body.connectionData;

    const fieldsToCompare = [
      'clientId',
      'endpoint',
      'path',
      'port',
      'prefix',
      'tls',
      'username',
      'websocket',
      'authentication'
    ];

    // submit if name changed (new broker) or additionalImeis changed in every case
    if (
      originalData?.name === body?.name &&
      originalData?.brokerIsDefault === body?.brokerIsDefault &&
      !newData.authentication &&
      originalBody.additionalImeis.slice().sort().toString() ===
        body.additionalImeis.slice().sort().toString()
    ) {
      // else: check if any of the other values changed
      const isEqual = fieldsToCompare.every(
        (field) =>
          newData[field] === null ||
          originalData[field]?.toString() === newData[field]?.toString()
      );
      return !isEqual;
    } else return true;
  };

  // newsletter subscription
  const handleNewsletterSubscription = async () => {
    try {
      if (process.env.REACT_APP_NEWS_URL) {
        let res = await fetch(
          process.env.REACT_APP_NEWS_URL +
            '/api/v3.3/subscribers/listid.xmljson',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              EmailAddress: email,
              Name: firstName + ' ' + lastName,
              Resubscribe: false,
              RestartSubscriptionBasedAutoresponders: false,
              ConsentToTrack: 'No'
            })
          }
        );

        if (!res.ok) {
          console.error('Error subscribing to Newsletter');
          console.error('Response', res);
        } else {
          console.log('Successfully subscribed to Newsletter');
        }
      }
    } catch (error) {
      console.error('Error subscribing to Newsletter');
      console.error(error);
    }
  };

  const handlePasswordReset = async (pEmail: String) => {
    setLoading(true);
    try {
      const res = await fetch(
        `/api/v1/mqtt/assignment/${customerNr}/reset/password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: pEmail,
            language: currentLanguage
          })
        }
      );
      setLoading(false);
      if (res.status === 202) {
        resetForm();
        setShowSuccessModal(true);
        setSuccessModalText(t('mqtt.password.email_sent_mqtt_reset'));
      } else if (res.status === 401) {
        setErrorMessageBody(t('mqtt.errors.email_not_linked'));
        setErrorModalOpen(true);
      } else {
        setErrorMessageBody(t('errors.500'));
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  const handlePasswordRegistration = async (
    newMqttPassword: String,
    pEmail: String
  ) => {
    setLoading(true);
    try {
      const res = await fetch(
        `/api/v1/mqtt/assignment/${customerNr}/register`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            mqttPassword: newMqttPassword,
            email: pEmail,
            imei,
            language: currentLanguage
          })
        }
      );
      setLoading(false);
      if (res.status === 204) {
        resetForm();
        setShowSuccessModal(true);
        setSuccessModalText(t('mqtt.password.email_sent_password'));
        setHasMqttAuthentication(true);
      } else if (res.status === 409) {
        setErrorMessageBody(t('mqtt.errors.password_cannot_be_reset'));
        setErrorModalOpen(true);
      } else {
        setErrorMessageBody(t('errors.500'));
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error registrating password:', error);
    }
  };

  const handleMqttLogin = async (mqttPassword: String) => {
    setLoading(true);
    try {
      const res = await fetch(`/api/v1/mqtt/assignment/${customerNr}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ mqttPassword, imei })
      });
      setLoading(false);
      if (res.status === 200) {
        const data = await res.json();
        setToken(data.token);
        if (data.targetSystem.mqttId !== null) {
          setOriginalBroker(data.targetSystem);
          setName(
            data.targetSystem.mqttAssignment.name.replace(`${customerNr}-`, '')
          );
          setEndpoint(data.targetSystem.mqttAssignment.endpoint);
          setPort(data.targetSystem.mqttAssignment.port);
          setClientId(data.targetSystem.mqttAssignment.clientId);
          setPrefix(data.targetSystem.mqttAssignment.prefix);
          setTls(data.targetSystem.mqttAssignment.tls);
          setWebsocket(data.targetSystem.mqttAssignment.websocket);
          setBrokerIsDefault(data.targetSystem.mqttAssignment.brokerIsDefault);
          setWebsocketPath(data.targetSystem.mqttAssignment.path || '');
          setUsername(data.targetSystem.mqttAssignment.username || '');
          setAuthentication(data.targetSystem.mqttAssignment.authentication);
          setAdditionalImeis(data.targetSystem.additionalImeis);
          setAdditionalImeisInfo(
            data.targetSystem.additionalImeis.map((pImei: string) => {
              return {
                imei: pImei,
                mqttId: data.targetSystem.mqttId
              };
            })
          );
        }
      } else if (res.status === 401) {
        // setErrorMessageBody('Das eingegebene Passwort ist nicht korrekt.');
        setErrorMessageBody(t('mqtt.errors.incorrect_password'));
        setErrorModalOpen(true);
      } else {
        setErrorMessageBody(t('errors.500'));
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.error('Error logging in:', error);
    }
  };

  // check imei if exists. return article type (minion / io-key) and device type (cumulocity / apollo / cloudtocloud)
  const handleImeiCheck = async (imei: string, pToken?: string) => {
    setLoading(true);

    try {
      let res = await fetch(
        `/api/v1/deviceType?imei=` + imei + '&customerId=' + customerNr,
        pToken || token
          ? {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${pToken || token}`
              }
            }
          : undefined
      );

      setLoading(false);
      if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else if (res.status === 409) {
          const data = await res.json();
          if (data.errorKey === 'device_already_connected_to_tenant')
            setErrorMessageBody(t('errors.device_already_connected_to_tenant'));
          else
            setErrorMessageBody(
              t('errors.customerId_already_connected_to_tenant')
            );
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        const data = await res.json();

        setType(data.type);
        setArticle(data.articleNumber?.startsWith('MN') ? 'minion' : 'io-key');

        if (data.id) {
          if (data.hasMqttAuthentication) setHasMqttAuthentication(true);

          if (
            data.mqttAssignment &&
            Object.keys(data.mqttAssignment).length >= 1
          ) {
            setOriginalBroker(data);
            setName(data.mqttAssignment?.name.replace(`${customerNr}-`, ''));
            setEndpoint(data.mqttAssignment.endpoint);
            setPort(data.mqttAssignment.port);
            setClientId(data.mqttAssignment.clientId);
            setPrefix(data.mqttAssignment.prefix);
            setTls(data.mqttAssignment.tls);
            setWebsocket(data.mqttAssignment.websocket);
            setWebsocketPath(data.mqttAssignment.path || '');
            setUsername(data.mqttAssignment.username || '');
            setAuthentication(data.mqttAssignment.authentication);
            setBrokerIsDefault(data.mqttAssignment.brokerIsDefault);
            setAdditionalImeis(data.additionalImeis);
            setAdditionalImeisInfo(
              data.additionalImeis.map((pImei: string) => {
                return {
                  imei: pImei,
                  mqttId: data.mqttId
                };
              })
            );
          } else {
            if (!additionalImeis.includes(imei))
              setAdditionalImeis((prev: any) => [imei, ...prev]);
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  // check if cumulocity-url is already in use
  const handleUrlCheck = async (url: string) => {
    setLoading(true);
    try {
      let res = await fetch(`/api/v1/tenant?url=${url}`, {
        method: 'GET'
      });
      setLoading(false);
      if (!res.ok) {
        if (res.status === 400) {
          setErrorMessageBody(t('errors.form'));
          setErrorModalOpen(true);
        } else if (res.status === 500) {
          setErrorMessageBody(t('errors.500'));
          setErrorModalOpen(true);
        } else {
          setErrorMessageBody(t('errors.general'));
          setErrorModalOpen(true);
        }
      } else {
        const data = await res.json();
        return data.tenantExists;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessageBody(t('errors.general'));
      setErrorModalOpen(true);
    }
  };

  // delete broker
  const handleBrokerDeletion = async (mqttPassword: String, mqttId: String) => {
    setLoading(true);
    const body: ICloudToCloudBody = {
      imei: imei,
      customerId: customerNr,
      language: currentLanguage,
      additionalImeis,
      connectionData: {
        clientId,
        endpoint: endpoint,
        password: '',
        path: websocket ? websocketPath : null,
        port: port === '' ? 1883 : Number(port),
        prefix,
        tls,
        username: authentication ? username : null,
        websocket,
        authentication
      },
      name: customerNr + '-' + name,
      brokerIsDefault
    };
    let res = await fetch(`/api/v1/mqtt/assignment/${customerNr}/${mqttId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ mqttPassword })
    });
    setLoading(false);
    if (res.status === 202) {
      setStoredBrokers((prev: any) => prev.filter((b: any) => b.id !== mqttId));
      setShowDeleteBrokerModal(false);
      setMqttIdToDelete('');
      if (!checkForChangesInMqttBody(originalBroker, body)) {
        resetMqttInputs();
      }
      setAdditionalImeisInfo((prevInfos: any[]) => {
        return prevInfos.map((info: any) => {
          return {
            ...info,
            mqttId: info.mqttId === mqttId ? null : info.mqttId
          };
        });
      });
    } else if (res.status === 401) {
      setDeletionError(t('mqtt.errors.incorrect_password'));
    } else {
      setShowDeleteBrokerModal(false);
      setMqttIdToDelete('');
      setErrorMessageBody(t('errors.500'));
      setErrorModalOpen(true);
    }
  };

  // activate a device
  const handleFormSubmit = async (
    body: IApolloBody | ICumulocityBody | ICloudToCloudBody
  ) => {
    setLoading(true);
    if (
      Object.keys(originalBroker).length >= 1 &&
      !checkForChangesInMqttBody(originalBroker, body)
    ) {
      setErrorMessageBody(t('mqtt.errors.no_changes_made'));
      setErrorModalOpen(true);
      setLoading(false);
    } else {
      // Authorization for mqtt; else not needed
      try {
        let res = await fetch('/api/v1/provision', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token ? `Bearer ${token}` : ''
          },
          body: JSON.stringify(body)
        });
        const data = await res.json();

        setLoading(false);
        if (res.status === 299) {
          setShowSuccessModalCumulocity(true);
          resetForm();
        } else if (!res.ok) {
          if (res.status === 400) {
            setErrorMessageBody(t('errors.form'));
            setErrorModalOpen(true);
          } else if (res.status === 500) {
            setErrorMessageBody(t('errors.500'));
            setErrorModalOpen(true);
          } else if (res.status === 401) {
            setErrorMessageBody(t('mqtt.errors.auth_failed'));
            setErrorModalOpen(true);
          } else if (res.status === 404) {
            setErrorMessageBody(
              type === 'apollo'
                ? t('errors.apollo_id')
                : t('errors.customer_id')
            );
            setErrorModalOpen(true);
          } else if (res.status === 409) {
            if (type === 'apollo') {
              setErrorMessageBody(t('errors.apollo_id_in_use'));
            } else {
              if (data.errorKey === 'device_already_connected_to_tenant')
                setErrorMessageBody(
                  t('errors.device_already_connected_to_tenant')
                );
              else
                setErrorMessageBody(
                  t('errors.customerId_already_connected_to_tenant')
                );
            }
            setErrorModalOpen(true);
          } else {
            setErrorMessageBody(t('errors.general'));
            setErrorModalOpen(true);
          }
        } else {
          const setupElement = document.getElementById('setup');
          if (setupElement) {
            setupElement.scrollIntoView({ behavior: 'smooth' });
          }
          //@ts-ignore
          if (type === 'cumulocity') {
            setShowSuccessModalCumulocity(true);
            resetForm();
          } else if (type === 'cloudtocloud') {
            setSuccessModalCloudToCloudTable(data?.message);
            setShowSuccessModalCloudToCloud(true);
            resetForm();
          } else if (type === 'apollo') {
            setShowSuccessModalApollo(true);
            setImei('');
            setImeiValidated(true);
          }
        }
      } catch (error) {
        setLoading(false);
        setErrorMessageBody(t('errors.general'));
        setErrorModalOpen(true);
      }
    }
  };

  // for navbar effect
  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 0;
      setIsScrolled(scrolled);
    };
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <NavBar
        setLanguage={setLanguage}
        currentLanguage={currentLanguage}
        isScrolled={isScrolled}
      />
      <Animation>
        <div
          id="register"
          className="w-full flex flex-row justify-center items-center z-1 min-h-[80vh] relative"
        >
          <div className="bg-white/80 w-11/12 max-w-[700px] my-12 join join-vertical rounded-none">
            <div className="card shadow-xl h-fit join-item join-horizontal z-2">
              <Routes>
                <Route
                  path="/"
                  element={
                    <Fragment>
                      <StarterForm
                        currentLanguage={currentLanguage}
                        setImei={setImei}
                        setCustomerNr={setCustomerNr}
                        handleImeiCheck={handleImeiCheck}
                        imei={imei}
                        loading={loading}
                        customerNr={customerNr}
                        checkSuccessfull={imeiCheckSuccessfull}
                        setShowSmallprintModal={setShowSmallprintModal}
                        setSmallprintModalImageSrc={setSmallprintModalImageSrc}
                        setSmallprintModalText={setSmallprintModalText}
                        setImeiValidated={setImeiValidated}
                        setCustomerNrValidated={setCustomerNrValidated}
                        imeiValidated={imeiValidated}
                        customerNrValidated={customerNrValidated}
                        type={type}
                        formFirstRender={starterFormFirstRender}
                        setFormFirstRender={setStarterFormFirstRender}
                        starterFormValidated={starterFormValidated}
                        setStarterFormValidated={setStarterFormValidated}
                        setToken={setToken}
                      />
                      <FormLogic
                        handleNewsletterSubscription={
                          handleNewsletterSubscription
                        }
                        imei={imei}
                        customerId={customerNr}
                        email={email}
                        password={password}
                        passwordConfirm={passwordConfirm}
                        loading={loading}
                        setSalutation={setSalutation}
                        setSalutationCode={setSalutationCode}
                        setFirstName={setFirstName}
                        setLastName={setLastName}
                        setEmail={setEmail}
                        setCompany={setCompany}
                        setIndustry={setIndustry}
                        setPhone={setPhone}
                        setStreet={setStreet}
                        setPostcode={setPostcode}
                        setCity={setCity}
                        setPassword={setPassword}
                        setPasswordConfirm={setPasswordConfirm}
                        setAcceptToc={setAcceptToc}
                        setAcceptNewsletter={setAcceptNewsletter}
                        acceptToc={acceptToc}
                        acceptNewsletter={acceptNewsletter}
                        type={type}
                        setErrorModalOpen={setErrorModalOpen}
                        setErrorMessageBody={setErrorMessageBody}
                        t={t}
                        setType={setType}
                        setImeiCheckSuccessfull={setImeiCheckSuccessfull}
                        setApolloId={setApolloId}
                        handleFormSubmit={handleFormSubmit}
                        apolloId={apolloId}
                        salutation={salutation}
                        salutationCode={salutationCode}
                        firstName={firstName}
                        lastName={lastName}
                        company={company}
                        industry={industry}
                        phone={phone}
                        street={street}
                        postcode={postcode}
                        city={city}
                        currentLanguage={currentLanguage}
                        url={url}
                        setUrl={setUrl}
                        setShowSmallprintModal={setShowSmallprintModal}
                        setSmallprintModalImageSrc={setSmallprintModalImageSrc}
                        setSmallprintModalText={setSmallprintModalText}
                        customerIdValidated={customerNrValidated}
                        imeiValidated={imeiValidated}
                        handleUrlCheck={handleUrlCheck}
                        name={name}
                        setName={setName}
                        endpoint={endpoint}
                        setEndpoint={setEndpoint}
                        port={port}
                        setPort={setPort}
                        tls={tls}
                        setTls={setTls}
                        websocketPath={websocketPath}
                        setWebsocketPath={setWebsocketPath}
                        authentication={authentication}
                        setAuthentication={setAuthentication}
                        username={username}
                        setUsername={setUsername}
                        additionalImeis={additionalImeis}
                        setAdditionalImeis={setAdditionalImeis}
                        prefix={prefix}
                        setPrefix={setPrefix}
                        clientId={clientId}
                        setClientId={setClientId}
                        hasMqttAuthentication={hasMqttAuthentication}
                        setHasMqttAuthentication={setHasMqttAuthentication}
                        token={token}
                        setToken={setToken}
                        handlePasswordRegistration={handlePasswordRegistration}
                        handlePasswordReset={handlePasswordReset}
                        handleMqttLogin={handleMqttLogin}
                        websocket={websocket}
                        setWebsocket={setWebsocket}
                        setMqttIdToDelete={setMqttIdToDelete}
                        setShowDeleteBrokerModal={setShowDeleteBrokerModal}
                        storedBrokers={storedBrokers}
                        setStoredBrokers={setStoredBrokers}
                        mqttIdToDelete={mqttIdToDelete}
                        additionalImeisInfo={additionalImeisInfo}
                        setAdditionalImeisInfo={setAdditionalImeisInfo}
                        brokerIsDefault={brokerIsDefault}
                        setBrokerIsDefault={setBrokerIsDefault}
                      />
                    </Fragment>
                  }
                />
                <Route
                  path="/apollo/register/confirm"
                  element={
                    <ConfirmSignupPage
                      setLanguage={setLanguage}
                      currentLanguage={currentLanguage}
                    />
                  }
                />
                <Route
                  path="/apollo/register"
                  element={
                    <RegistrationPage
                      setLanguage={setLanguage}
                      currentLanguage={currentLanguage}
                      setShowSmallprintModal={setShowSmallprintModal}
                      setSmallprintModalImageSrc={setSmallprintModalImageSrc}
                      setSmallprintModalText={setSmallprintModalText}
                    />
                  }
                />
                <Route
                  path="/apollo"
                  element={
                    <ActivateApolloPage
                      currentLanguage={currentLanguage}
                      setShowSmallprintModal={setShowSmallprintModal}
                      setSmallprintModalImageSrc={setSmallprintModalImageSrc}
                      setSmallprintModalText={setSmallprintModalText}
                    />
                  }
                />
                <Route
                  path="/confirm"
                  element={
                    <ConfirmPage
                      setLanguage={setLanguage}
                      currentLanguage={currentLanguage}
                    />
                  }
                ></Route>
                <Route
                  path="/mqtt/reset/confirm"
                  element={
                    <ResetMqttPasswordHandlerPage
                      customerId={customerNr}
                      setLanguage={setLanguage}
                      currentLanguage={currentLanguage}
                    />
                  }
                ></Route>
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </div>
          </div>
        </div>
      </Animation>

      <div
        className="w-full flex justify-center items-center z-1 min-h-[70vh] p-8 my-12"
        id="setup"
      >
        <div className="w-11/12 max-w-[1300px] text-center">
          <h2
            className="bold text-4xl"
            dangerouslySetInnerHTML={{ __html: t('steps.title') }}
          />
          <div className="grid grid-cols-12 gap-x-4 my-24 place-items-center">
            <Step
              title={t('steps.one.title')}
              subtitle={t('steps.one.subtitle')}
              text={t('steps.one.text')}
              img="/img/iokey_step1.png"
            />
            <Step
              title={t('steps.two.title')}
              subtitle={t('steps.two.subtitle')}
              text={t('steps.two.text')}
              img="/img/iokey_step2.png"
            />
            <Step
              title={t('steps.three.title')}
              subtitle={t('steps.three.subtitle')}
              text={t('steps.three.text')}
              img="/img/iokey_step3.png"
            />
          </div>
          <div className="grid grid-cols-12 gap-x-4 my-24 place-items-center">
            <Step
              title={t('steps_minion.one.title')}
              subtitle={t('steps_minion.one.subtitle')}
              text={t('steps_minion.one.text')}
              img="/img/minion_step1.png"
            />
            <Step
              title={t('steps_minion.two.title')}
              subtitle={t('steps_minion.two.subtitle')}
              text={t('steps_minion.two.text')}
              img="/img/minion_step2.png"
            />
            <Step
              title={t('steps_minion.three.title')}
              subtitle={t('steps_minion.three.subtitle')}
              text={t('steps_minion.three.text')}
              img="/img/minion_step3.png"
            />
          </div>
        </div>
      </div>

      <div className="w-full flex justify-center items-center z-1 min-h-[60vh] p-8 mb-12">
        <div className="w-full flex flex-col justify-center text-center gap-y-16">
          <h2 className="bold text-4xl">{t('hero.title')}</h2>
          <Animation className="w-full flex flex-col justify-center">
            <img
              alt=""
              className="object-contain h-[300px]"
              src="/img/iokey.png"
            />
          </Animation>
        </div>
      </div>

      <div className="w-full flex text-left justify-start md:justify-center flex-col md:flex-row gap-x-12 gap-y-1 z-1 p-8">
        <button
          className="hover:text-primary text-left"
          onClick={() => setImprintOpen(true)}
        >
          {t('footer.site_notice.label')}
        </button>
        <a
          href={t('footer.privacy.customurl')}
          data-title={t('footer.privacy.title')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.privacy.label')}
        </a>
        <a
          href={t('footer.terms_and_conditions.customurl')}
          data-title={t('footer.terms_and_conditions')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.terms_and_conditions.label')}
        </a>
        <a
          href={t('footer.ats_eula.customurl')}
          data-title={t('footer.ats_eula')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.ats_eula.label')}
        </a>
        <a
          href={t('footer.ats_foss_apollo.customurl')}
          data-title={t('footer.ats_foss_apollo')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.ats_foss_apollo.label')}
        </a>
        <a
          href={t('footer.ats_foss_iokey.customurl')}
          data-title={t('footer.ats_foss_iokey')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.ats_foss_iokey.label')}
        </a>
        <a
          href={t('footer.ats_foss_minion.customurl')}
          data-title={t('footer.ats_foss_minion')}
          target="_blank"
          rel="noreferrer"
        >
          {t('footer.ats_foss_minion.label')}
        </a>
      </div>

      <ImprintModal
        imprintIsOpen={imprintIsOpen}
        setImprintOpen={setImprintOpen}
        t={t}
      />

      <ErrorModal
        errorModalIsOpen={errorModalIsOpen}
        setErrorModalOpen={setErrorModalOpen}
        t={t}
        errorMessageBody={errorMessageBody}
        errorMessageHeading={errorMessageHeading}
      />

      <SuccessModal
        t={t}
        showSuccessModal={showSuccessModal}
        setShowSuccessModal={setShowSuccessModal}
        successModalText={successModalText}
      />
      <SuccessModalApollo
        t={t}
        showSuccessModalApollo={showSuccessModalApollo}
        setShowSuccessModalApollo={setShowSuccessModalApollo}
      />

      <SuccessModalCloudToCloud
        t={t}
        showSuccessModalCloudToCloud={showSuccessModalCloudToCloud}
        setShowSuccessModalCloudToCloud={setShowSuccessModalCloudToCloud}
        successModalCloudToCloudTable={successModalCloudToCloudTable}
      />

      <SuccessModalCumulocity
        t={t}
        showSuccessModalCumulocity={showSuccessModalCumulocity}
        setShowSuccessModalCumulocity={setShowSuccessModalCumulocity}
        domain={url}
        article={article}
      />

      <DeleteBrokerModal
        loading={loading}
        t={t}
        showDeleteBrokerModal={showDeleteBrokerModal}
        setShowDeleteBrokerModal={setShowDeleteBrokerModal}
        handleBrokerDeletion={handleBrokerDeletion}
        selectedMqttAssignmentId={mqttIdToDelete}
        deletionError={deletionError}
        setDeletionError={setDeletionError}
      />

      <SmallprintModal
        t={t}
        showSmallprintModal={showSmallprintModal}
        setShowSmallprintModal={setShowSmallprintModal}
        smallprintModalText={smallprintModalText}
        smallprintModalImageSrc={smallprintModalImageSrc}
      />
    </Router>
  );
}

export default App;
