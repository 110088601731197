import React from 'react';

const Checkbox: React.FC<{
  isChecked: boolean;
  setIsChecked: any;
  label: string;
  required: boolean;
  disabled?: boolean;
}> = ({ isChecked, setIsChecked, label, required, disabled }) => {
  return (
    <label
      htmlFor={'checkboxlabel' + label}
      className="flex cursor-pointer select-none items-center"
    >
      <div className="relative">
        <input
          type="checkbox"
          id={'checkboxlabel' + label}
          className="sr-only"
          required={required}
          checked={isChecked}
          onChange={(e: any) => {
            setIsChecked(e.target.checked);
          }}
          disabled={disabled ? disabled : false}
        />
        <div
          className={`relative mr-4 pl-2 h-8 w-8 !p-0 border ${
            isChecked && 'border-primary bg-gray dark:bg-transparent'
          }`}
        >
          <span
            className={`absolute !p-0 !m-0 h-[81%] w-[85%] top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 ${
              isChecked && 'bg-primary'
            }`}
          />
        </div>
      </div>
      {label}
      {required && '*'}
    </label>
  );
};
export default Checkbox;
